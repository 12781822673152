import { useContext } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { getPrinterByName } from "@grabcad/printer-information";
import DefaultPrinterImage from "../../images/default.png";
import moment from "moment-timezone";
import { Checkbox } from "grabcad-ui-elements";
import { HighlightableText, StyledJobProgressBar } from "./PrinterCard";
import { PrinterStatusLabel } from "./PrinterStatusLabel";
import { IPrinter } from "grabcad-printers-api";

export interface PrinterRowProps {
    printer: IPrinter;
    searchString: string;
    onClick?: (printerId: string) => void;
    selectedPrinterIds?: string[];
}

export const PrinterRow = ({
    printer,
    searchString,
    onClick,
    selectedPrinterIds,
}: PrinterRowProps) => {
    const { t } = useContext(ApplicationContext);
    const printerType = printer.getModelName() || t("machines.unknown");
    const printerName = printer.getName() || printerType;
    const printerImage = getPrinterByName(printerType)?.imageUri || DefaultPrinterImage;
    const job = printer.getJobs().getCurrent();
    const currentJob = job && {
        name: job.getName(),
        progress: Math.floor(job.getProgress() * 100),
        endTime: moment(job.getEndDate()).format("MMMM D h:mm A"),
    };
    return (
        <tr onClick={() => onClick?.(printer.getId())}>
            <td>
                <div className="flex-row">
                    {selectedPrinterIds && (
                        <Checkbox checked={selectedPrinterIds.includes(printer.getId())} />
                    )}
                    <img src={printerImage} />
                    <div>
                        <HighlightableText text={printerName} highlights={searchString} />
                        <p className="subheading">
                            <HighlightableText text={printerType} highlights={searchString} />
                        </p>
                    </div>
                </div>
            </td>
            <td>
                <PrinterStatusLabel printer={printer} />
            </td>
            <td>
                <HighlightableText text={currentJob?.name} highlights={searchString} />
            </td>
            <td>
                {currentJob && (
                    <div className="flex-row">
                        <div className="percentage">{currentJob.progress}%</div>
                        <StyledJobProgressBar
                            percent={currentJob.progress}
                            size="tiny"
                            color="blue"
                        />
                    </div>
                )}
            </td>
        </tr>
    );
};
