import { UserContext } from "../../components/User/UserProvider";
import { Notifier } from "../../view/Notifier";
import { useContext, useEffect, useState } from "react";
import { match as routerMatch } from "react-router-dom";
import { Role, useUserGroupsQuery } from "../../graphql";
import type {
    PrinterGroup,
    UserGroupPrinterGroupAccessOutput as UserGroupPrinterGroupAccess,
} from "@grabcad/company-server-shared/dist/types";
import { PATHS } from "../../Routes";
import { IScreenProps } from "../Screen";
import styled, { Button, Icon, Message } from "grabcad-ui-elements";
import { useMutation, useQuery } from "@apollo/client";
import { PRINTER_GROUPS } from "@grabcad/company-server-shared/dist/graphql/Queries/PrinterGroups";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { ApplicationContext } from "../../components/ApplicationProvider";
import PrinterGroupModal, { PrinterGroupModalType } from "./PrinterGroupModal";
import { UserGroupMultiSelect } from "../../view/UserGroupMultiSelect";
import { USER_GROUP_PRINTER_GROUP_ACCESSES } from "@grabcad/company-server-shared/dist/graphql/Queries/UserGroupPrinterGroupAccesses";
import { UPDATE_USER_GROUP_PRINTER_GROUP_ACCESSES } from "@grabcad/company-server-shared/dist/graphql/Mutations/UserGroupPrinterGroupAccess";
import { IPrinter } from "grabcad-printers-api";
import { PrinterDetailsModal } from "./PrinterDetailsModal";
import { UPDATE_PRINTER_GROUP } from "@grabcad/company-server-shared/dist/graphql/Mutations/PrinterGroups";
import { NoPrinter } from "./NoPrinter";
import { useGetAllPrinters } from "./UseGetAllPrinters";
import { Printers } from "./Printers";
import { useCompanyLicensesQuery } from "../../graphql/Queries/Licenses/GetCompanyLicenses";

const Header = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        margin-bottom: 20px;
    }
    .ui.selection.dropdown {
        position: relative;
        z-index: 200;
    }
`;

const PrinterContainerHeader = styled.div`
    display: flex;
    margin-top: 18px;
    align-items: center;
    justify-content: space-between;

    h3 {
        margin: 0;
    }
`;

const WarningMessage = styled(Message)`
    &.ui.warning.message {
        margin-top: 0;
        margin-bottom: 20px;
        color: black;
        background-color: #fef5ec;
        border: 1px solid rgba(255, 136, 0, 0.3);
        box-shadow: none;
    }

    & > .info.circle.icon {
        color: #ff8800;
    }
`;
export interface PrinterGroupProps extends IScreenProps {
    match: routerMatch<{ id?: string }>;
}

export const PrinterGroupList: React.FC<PrinterGroupProps> = ({ history, match }) => {
    const { t } = useContext(ApplicationContext);
    const { role } = useContext(UserContext);
    const { enabledFeatures } = useCompanyLicensesQuery();
    const printerGroupId = Number(match.params.id);

    const { loadingPrinters, printers, errorOccurred, errorMessage, setLoadingPrinters } =
        useGetAllPrinters();
    const { userGroups, loadingUserGroups } = useUserGroupsQuery();

    const [editPrinterGroupModalOpen, setEditPrinterGroupModalOpen] = useState<boolean>(false);

    const { loading: printerGroupsLoading, data: printerGroupsData } = useQuery<{
        printerGroups: PrinterGroup[];
    }>(PRINTER_GROUPS, {
        onError: (error) => Notifier.error(error),
    });

    const { loading: accessesLoading, data: accessesData } = useQuery<{
        userGroupPrinterGroupAccesses: UserGroupPrinterGroupAccess[];
    }>(USER_GROUP_PRINTER_GROUP_ACCESSES, {
        onError: (error) => Notifier.error(error),
    });

    const [updateUserGroupPrinterGroupAccesses] = useMutation(
        UPDATE_USER_GROUP_PRINTER_GROUP_ACCESSES
    );
    const [updatePrinterGroup] = useMutation(UPDATE_PRINTER_GROUP);

    if (role !== Role.CompanyAdmin) {
        history.push(PATHS.adminHome);
        return <></>;
    }

    /* eslint-disable react-hooks/rules-of-hooks */
    const [userGroupIds, setUserGroupIds] = useState<number[]>([]);

    const [printerModalOpen, setPrinterModalOpen] = useState(false);
    const [modalPrinter, setModalPrinter] = useState<IPrinter | null>(null);

    const openModal = (id: string) => {
        const printer = printers.find((p) => p.getId() === id);
        if (printer) {
            setModalPrinter(printer);
            setPrinterModalOpen(true);
        }
    };

    useEffect(() => {
        if (accessesData?.userGroupPrinterGroupAccesses) {
            setUserGroupIds(
                accessesData?.userGroupPrinterGroupAccesses
                    .filter((access) => access.printerGroup.id === printerGroupId)
                    .map((access) => access.userGroup.id)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessesData?.userGroupPrinterGroupAccesses]);
    /* eslint-enable react-hooks/rules-of-hooks */

    /**
     * Update user group IDs for user-printer group mappings.
     */
    const updateUserGroupIds = (ids: number[]) => {
        const userGroupIdToAdd = ids.find((localId) => !userGroupIds.includes(localId));
        const userGroupIdToRemove = userGroupIds.find((remoteId) => !ids.includes(remoteId));

        if (userGroupIdToAdd) {
            void updateUserGroupPrinterGroupAccesses({
                variables: {
                    toCreate: [
                        {
                            userGroupId: userGroupIdToAdd,
                            printerGroupId: printerGroupId,
                        },
                    ],
                },
                update: (cache, { data }) => {
                    // extract current access mappings from Apollo cache
                    const { userGroupPrinterGroupAccesses } =
                        cache.readQuery<{
                            userGroupPrinterGroupAccesses: UserGroupPrinterGroupAccess[];
                        }>({ query: USER_GROUP_PRINTER_GROUP_ACCESSES }) || {};

                    // if cache hit, add new access mapping to cache
                    if (userGroupPrinterGroupAccesses) {
                        const newUserGroupPrinterGroupAccesses =
                            data.updateUserGroupPrinterGroupAccesses;

                        cache.writeQuery({
                            query: USER_GROUP_PRINTER_GROUP_ACCESSES,
                            data: {
                                userGroupPrinterGroupAccesses: [
                                    ...userGroupPrinterGroupAccesses,
                                    ...newUserGroupPrinterGroupAccesses,
                                ],
                            },
                        });
                    }
                },
                onError: (error) => Notifier.error(error),
                onCompleted: () => {
                    setUserGroupIds([...userGroupIds, userGroupIdToAdd]);

                    Notifier.success(t("printer_groups.user_group_added"));
                },
            });
        }

        if (userGroupIdToRemove) {
            void updateUserGroupPrinterGroupAccesses({
                variables: {
                    toDelete: [
                        // find ID of access entity linking printer and user group
                        accessesData?.userGroupPrinterGroupAccesses.find(
                            (access) =>
                                // match printer group
                                access.printerGroup.id === printerGroupId &&
                                // match user group
                                access.userGroup.id === userGroupIdToRemove
                        )!.id,
                    ],
                },
                update: (cache) => {
                    // extract current access mappings from Apollo cache
                    const { userGroupPrinterGroupAccesses } =
                        cache.readQuery<{
                            userGroupPrinterGroupAccesses: UserGroupPrinterGroupAccess[];
                        }>({ query: USER_GROUP_PRINTER_GROUP_ACCESSES }) || {};

                    // if cache hit, delete access mapping from cache
                    if (userGroupPrinterGroupAccesses) {
                        cache.writeQuery({
                            query: USER_GROUP_PRINTER_GROUP_ACCESSES,
                            data: {
                                userGroupPrinterGroupAccesses: userGroupPrinterGroupAccesses.filter(
                                    (access) =>
                                        // keep everything except those that match both printer- and user- group
                                        !(
                                            access.printerGroup.id === printerGroupId &&
                                            access.userGroup.id === userGroupIdToRemove
                                        )
                                ),
                            },
                        });
                    }
                },
                onError: (error) => Notifier.error(error),
                onCompleted: () => {
                    setUserGroupIds(
                        userGroupIds.filter((groupId) => groupId !== userGroupIdToRemove)
                    );

                    Notifier.success(t("printer_groups.user_group_removed"));
                },
            });
        }
    };

    if (
        printerGroupsLoading ||
        !printerGroupsData ||
        accessesLoading ||
        !accessesData ||
        loadingUserGroups
    ) {
        return <div className="ui loader active" />;
    }

    const printerGroup = printerGroupsData.printerGroups.find(
        (group) => group.id === printerGroupId
    );
    const someUserHasRegistered = userGroups.some(
        (userGroup) =>
            userGroupIds.includes(userGroup.id) &&
            userGroup.users?.some((user) => user.hasRegistered)
    );
    const someUserGroupIsNonEmpty = userGroups.some(
        (userGroup) => userGroupIds.includes(userGroup.id) && userGroup.users.length > 0
    );

    const printerGroupPrinters: IPrinter[] = [];
    const printerGroupMissingPrinterIds: string[] = [];
    if (!loadingPrinters) {
        printerGroup?.printerIds?.forEach((id) => {
            const printer = printers.find((p) => p.getId() === id);
            if (printer) {
                printerGroupPrinters.push(printer);
            } else {
                printerGroupMissingPrinterIds.push(id);
            }
        });
    }

    const onClickRemoveOfflinePrinters = () => {
        void updatePrinterGroup({
            variables: {
                id: printerGroup?.id,
                name: printerGroup?.name,
                printerIdsToAdd: [],
                printerIdsToRemove: printerGroupMissingPrinterIds,
            },
            onError: (error) => Notifier.error(error),
            refetchQueries: [{ query: PRINTER_GROUPS }],
            onCompleted: () => {
                Notifier.success(t("printer_groups.offline_printers.successfully_removed"));
            },
        });
    };

    return printerGroup ? (
        <>
            <Breadcrumbs
                sections={[
                    {
                        label: t("printer_groups.breadcrumb"),
                        to: PATHS.printerGroups,
                    },
                    { label: printerGroup.name },
                ]}
            />

            <Header>
                <h2 className="page-header">{printerGroup.name}</h2>

                {/* display warning if no user groups are selected */}
                {!userGroupIds.length && enabledFeatures.accessControl && (
                    <WarningMessage warning className="qa-printer-group-no-user-group">
                        <Icon name="info circle" />
                        {t("printer_groups.no_user_group.title")}
                    </WarningMessage>
                )}
                {/* display warning if user groups are selected but none of the users are registered*/}
                {someUserGroupIsNonEmpty &&
                    !someUserHasRegistered &&
                    enabledFeatures.accessControl && (
                        <WarningMessage warning className="qa-printer-group-no-users-registered">
                            <Icon name="info circle" />
                            {t("printer_groups.no_user_registered.title")}
                        </WarningMessage>
                    )}
                {printerGroupMissingPrinterIds.length > 0 && (
                    <WarningMessage warning className="qa-printer-group-some-offline">
                        <Icon name="info circle" />

                        {t("printer_groups.offline_printers.some_offline")}
                        <a onClick={onClickRemoveOfflinePrinters}>
                            &nbsp;
                            {t("printer_groups.offline_printers.click_here")}
                            &nbsp;
                        </a>
                        {t("printer_groups.offline_printers.to_remove")}
                    </WarningMessage>
                )}
                {enabledFeatures.accessControl && (
                    <UserGroupMultiSelect
                        ids={userGroupIds}
                        onChange={updateUserGroupIds}
                        labelFontSize={18}
                    />
                )}

                <PrinterContainerHeader>
                    <h3>{t("printers.header")}</h3>

                    <Button
                        className="qa-printerGroups-editPrinterGroup ui button primary"
                        type="submit"
                        onClick={() => {
                            // open edit printer group modal
                            setEditPrinterGroupModalOpen(!editPrinterGroupModalOpen);
                        }}
                    >
                        {t("printer_groups.edit_printer_group")}
                    </Button>
                </PrinterContainerHeader>
            </Header>
            {loadingPrinters ? (
                <div className="ui loader active" />
            ) : !printers.length || errorOccurred ? (
                <NoPrinter errorOccurred={errorOccurred} errorMessage={errorMessage} />
            ) : (
                <Printers printers={printerGroupPrinters} onClick={(id) => openModal(id)} />
            )}

            <PrinterDetailsModal
                printer={modalPrinter}
                isOpen={printerModalOpen}
                onClose={() => setPrinterModalOpen(false)}
                history={history}
                onUpdate={() => setLoadingPrinters(true)}
            />
            {/* edit printer group modal */}
            <PrinterGroupModal
                type={PrinterGroupModalType.Edit}
                open={editPrinterGroupModalOpen}
                group={printerGroup}
                onClose={() => setEditPrinterGroupModalOpen(false)}
            />
        </>
    ) : null;
};
