import gql from "graphql-tag";
import { UpdateStrategy } from "../../graphql";

/**
 * graphql query to get all information for the version management form
 */
export const VERSION_MANAGEMENT_GET_FORM_DATA = gql`
    query versionManagementGetFormData {
        getCompanySoftwareVersion {
            updateStrategy
            bundle {
                id
                bundleVersion
            }
            tag {
                id
                name
            }
        }
        getSoftwareBundles {
            id
            bundleVersion
            tags {
                id
                name
            }
        }
        getSoftwareTags {
            id
            name
        }
    }
`;

/**
 * Represents a software bundle (having an id and a version)
 * there is more data available, but we only need the id and the bundleVersion
 */
export interface SoftwareBundle {
    id: number;
    bundleVersion: string;
}
/**
 * Represents a software tag (having an id and a name)
 */
export interface SoftwareTag {
    id: number;
    name: string;
}
/**
 * Represents a software version (having an id, a bundleVersion and an optional array of tags)
 */
export interface SoftwareVersion {
    id: number;
    bundleVersion: string;
    tags?: SoftwareTag[];
}

/**
 * Represents the result of the version management query
 */
export interface VersionManagementGetFormDataResult {
    getCompanySoftwareVersion: {
        updateStrategy?: UpdateStrategy;
        bundle?: SoftwareBundle;
        tag?: SoftwareTag;
    };
    getSoftwareBundles: SoftwareVersion[];
    getSoftwareTags: SoftwareTag[];
}

/**
 * graphql mutation to update the company software version data
 */
export const UPDATE_COMPANY_SOFTWARE_VERSION = gql`
    mutation updateCompanySoftwareVersion(
        $updateStrategy: String!
        $bundleVersion: Int
        $tag: Int
    ) {
        updateCompanySoftwareVersion(
            data: { updateStrategy: $updateStrategy, bundleVersion: $bundleVersion, tag: $tag }
        ) {
            id
        }
    }
`;
