import React, { useContext } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";

// Helper components for Printer Serial
interface CompanyTitleProps {
    companyName?: string;
}

export const CompanyTitle: React.FC<CompanyTitleProps> = ({ companyName }) => {
    const { t } = useContext(ApplicationContext);

    return (
        <label>
            <h3>
                {companyName} {t("gateways.gateways_title")}
            </h3>
        </label>
    );
};

export const GatewayText: React.FC = () => {
    const { t } = useContext(ApplicationContext);

    return (
        <>
            <span data-testid="add-more-gateways-text">{t("gateways.add_more_gateways_text")}</span>
            <span data-testid="printer-compatibility-text">
                {t("gateways.gateways_printer_compatibility_text")}
            </span>
        </>
    );
};

export const NoGatewaysMessage = () => {
    const { t } = useContext(ApplicationContext);

    return (
        <div style={{ textAlign: "center" }} data-testid="no-gateways-message">
            <span>{t("gateways.no_gateways_available_text")}</span>
        </div>
    );
};

// Reusable UI Components
export const PrinterSerialLabel = ({ printerSerial }: { printerSerial: string }) => (
    <label style={{ color: "#4183c4", fontWeight: 600 }}>
        <h3 data-testid="printer_serial_label">Client {printerSerial}</h3>
    </label>
);
