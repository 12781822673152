import { ChangeEvent } from "react";
import { TranslationFunction } from "../../components/ApplicationProvider";
import { UpdateStrategy } from "../../graphql";
import { Label, RadioButton, RadioButtonGroup } from "./VersionsStyles";

interface UpdateStrategyRadioButtonProps {
    id: string;
    value: UpdateStrategy;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    label: string;
}

/**
 * Internal component to simplify the radio button creation
 */
const UpdateStrategyRadioButton = ({
    id,
    value,
    onChange,
    checked,
    label,
}: UpdateStrategyRadioButtonProps) => {
    return (
        <>
            <RadioButton
                id={id}
                data-testid={id}
                type="radio"
                name="UpdateStrategy"
                value={value}
                onChange={onChange}
                checked={checked}
            />
            <Label htmlFor={id}>{label}</Label>
        </>
    );
};

interface UpdateStrategyRadioButtonsProps {
    onSelectNewStrategy: (value: UpdateStrategy) => void;
    currentUpdateStrategy: UpdateStrategy;
    t: TranslationFunction;
}

/**
 * Radio buttons for selecting the update strategy
 */
export const UpdateStrategyRadioButtons = ({
    onSelectNewStrategy,
    currentUpdateStrategy,
    t,
}: UpdateStrategyRadioButtonsProps) => {
    return (
        <RadioButtonGroup data-testid="update_strategy_radio_buttons">
            <UpdateStrategyRadioButton
                id="update_strategy.latest_release"
                value={UpdateStrategy.LatestRelease}
                onChange={() => onSelectNewStrategy(UpdateStrategy.LatestRelease)}
                checked={currentUpdateStrategy === UpdateStrategy.LatestRelease}
                label={t("version_management.update_strategies.latest_release")}
            />

            <UpdateStrategyRadioButton
                id="update_strategy.latest_tag_release"
                value={UpdateStrategy.LatestTaggedVersion}
                onChange={() => onSelectNewStrategy(UpdateStrategy.LatestTaggedVersion)}
                checked={currentUpdateStrategy === UpdateStrategy.LatestTaggedVersion}
                label={t("version_management.update_strategies.latest_tag_release")}
            />

            <UpdateStrategyRadioButton
                id="update_strategy.fixed_version"
                value={UpdateStrategy.FixedVersion}
                onChange={() => onSelectNewStrategy(UpdateStrategy.FixedVersion)}
                checked={currentUpdateStrategy === UpdateStrategy.FixedVersion}
                label={t("version_management.update_strategies.fixed_version")}
            />
        </RadioButtonGroup>
    );
};
