import classNames from "classnames";
import { Button } from "grabcad-ui-elements";
import { UpdateStrategy } from "../../graphql";
import { VersionsDropdown } from "./VersionsDropdown";
import { H2 } from "./VersionsStyles";
import { FunctionComponent, useState } from "react";
import { TranslationFunction } from "../../components/ApplicationProvider";
import { SoftwareBundle, SoftwareTag, SoftwareVersion } from "./VersionManagementQueries";
import { UpdateStrategyRadioButtons } from "./UpdateStrategyRadioButtons";

export interface VersionManagementFormProps {
    updateStrategy: UpdateStrategy;
    bundle?: SoftwareBundle;
    tag?: SoftwareTag;
    softwareBundles: SoftwareVersion[];
    softwareTags: SoftwareTag[];
    t: TranslationFunction;
    updateVersions: (
        newUpdateStrategy: UpdateStrategy,
        newBundle?: SoftwareBundle,
        newTag?: SoftwareTag
    ) => void;
}

/**
 * Form for selecting the GrabCAD Print version
 */
export const VersionManagementForm: FunctionComponent<VersionManagementFormProps> = ({
    updateStrategy,
    bundle,
    tag,
    softwareBundles,
    softwareTags,
    t,
    updateVersions,
}) => {
    /* State for the form */
    const [currentUpdateStrategy, setUpdateStrategy] = useState<UpdateStrategy>(updateStrategy);
    const [currentBundle, setBundle] = useState<SoftwareBundle | undefined>(bundle);
    const [currentTag, setTag] = useState<SoftwareTag | undefined>(tag);

    /* Options for versions Dropdown - we handle tagged versions and plain versions `version - tag,tag` and `version`*/
    const versionOptions = softwareBundles.map((swBundle) => {
        const bundleTags = swBundle.tags ?? [];
        let suffix = "";
        if (bundleTags.length > 0) {
            suffix = ` - [${bundleTags.map((bundleTag) => bundleTag.name).join(",")}]`;
        }
        return {
            text: `${swBundle.bundleVersion}${suffix}`,
            value: swBundle.id,
        };
    });

    /* Options for tags Dropdown */
    const tagOptions = softwareTags.map((swTag) => ({ text: swTag.name, value: swTag.id }));

    /* callback for radio buttons */
    const onSelectNewStrategy = (value: UpdateStrategy) => {
        setUpdateStrategy(value);
    };

    /* callback for version dropdown */
    const onSelectNewVersion = (_event: React.SyntheticEvent<HTMLElement>, newId: number) => {
        const selectedBundleVersion = softwareBundles.find((b) => b.id === newId);
        setBundle(selectedBundleVersion);
    };

    /* callback for tag dropdown */
    const onSelectNewTag = (_event: React.SyntheticEvent<HTMLElement>, newId: number) => {
        const selectedTag = softwareTags.find((b) => b.id === newId);
        setTag(selectedTag);
    };

    /** Check if the form is dirty
     * - Submit button is enabled when form is dirty and valid
     * - Cancel button is enabled when form is dirty
     */
    const isDirty = () => {
        const result =
            updateStrategy !== currentUpdateStrategy ||
            (currentUpdateStrategy === UpdateStrategy.FixedVersion &&
                bundle?.id !== currentBundle?.id) ||
            (currentUpdateStrategy === UpdateStrategy.LatestTaggedVersion &&
                currentTag?.id !== tag?.id);
        return result;
    };

    /** Check if the form is valid
     * - Submit button is enabled when form is dirty and valid
     * - Cancel button is enabled when form is dirty
     */
    const isValid = () => {
        switch (currentUpdateStrategy) {
            case UpdateStrategy.LatestRelease:
                return true;
            case UpdateStrategy.FixedVersion:
                return !!currentBundle;
            case UpdateStrategy.LatestTaggedVersion:
                return !!currentTag;
            default:
                return false;
        }
    };

    return (
        <div style={{ maxWidth: 550, paddingBottom: 25, marginTop: "32px" }}>
            <H2>{t("version_management.select_grabcad_print_version")}</H2>

            <UpdateStrategyRadioButtons
                onSelectNewStrategy={onSelectNewStrategy}
                currentUpdateStrategy={currentUpdateStrategy}
                t={t}
            />

            {/* Only show if we have selected FixedVersion */}
            <VersionsDropdown
                testId="version-management-versions-dropdown"
                visible={currentUpdateStrategy === UpdateStrategy.FixedVersion}
                heading={t("version_management.select_version")}
                placeholder={t("version_management.select_specific_version")}
                options={versionOptions}
                onChange={onSelectNewVersion}
                value={currentBundle?.id}
            />

            {/* Only show if we have selected LatestTaggedVersion */}
            <VersionsDropdown
                testId="version-management-tags-dropdown"
                visible={currentUpdateStrategy === UpdateStrategy.LatestTaggedVersion}
                heading={t("version_management.select_tag")}
                placeholder={t("version_management.select_specific_tag")}
                options={tagOptions}
                onChange={onSelectNewTag}
                value={currentTag?.id}
            />

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "32px" }}>
                <Button
                    data-testid={`version-management-cancel${isDirty() ? "" : "-disabled"}`}
                    id="qa-company-cancel"
                    type="button"
                    secondary
                    disabled={!isDirty()}
                    onClick={() => {
                        // Reset the state to the original values
                        setUpdateStrategy(updateStrategy ?? UpdateStrategy.LatestRelease);
                        setBundle(bundle);
                        setTag(tag);
                    }}
                >
                    {t("forms.cancel")}
                </Button>
                <Button
                    data-testid={`version-management-submit${
                        isDirty() && isValid() ? "" : "-disabled"
                    }`}
                    id="qa-company-submit"
                    type="submit"
                    className={classNames("primary right floated")}
                    disabled={!(isDirty() && isValid())}
                    onClick={() => updateVersions(currentUpdateStrategy, currentBundle, currentTag)}
                >
                    {t("forms.submit")}
                </Button>
            </div>
        </div>
    );
};
