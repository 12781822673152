import { FunctionComponent, useEffect, useState } from "react";
import { IScreenProps } from "../Screen";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult, useMutation } from "@apollo/client";
import { Notifier } from "../../view/Notifier";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { FormField, Icon, Label, Tab, Form } from "grabcad-ui-elements";
import { parseEmails } from "../../utils/email";
import { validate } from "isemail";
import { InfoBox } from "../../components/Styles";

export const LIST_USERS_WITH_STREAMS = gql`
    query listUsersWithStreams {
        listUsersWithStreams {
            users {
                id
                platform
                streamName
                user
            }
            totalCount
        }
    }
`;

export const UPDATE_USER_STREAM = gql`
    mutation updateUserStream($user: String, $platform: String, $streamName: String) {
        updateUserStream(user: $user, platform: $platform, streamName: $streamName) {
            id
            platform
            streamName
            user
        }
    }
`;

export const DELETE_USER_STREAM = gql`
    mutation deleteUserStream($user: String, $platform: String) {
        deleteUserStream(user: $user, platform: $platform)
    }
`;

interface UserStream {
    id: number;
    user: string;
    streamName: string;
    platform: string;
}

interface IPlatformProps {
    users: UserStream[];
    platform: string;
    stream: string;
}

interface ListUsersWithStreamsResult {
    listUsersWithStreams: {
        users: UserStream[];
        totalCount: number;
    };
}

const outerContainerStyle = {
    background: "white",
    border: "1px solid #d6d6d6",
    borderRadius: 2,
    display: "flex",
    flexWrap: "wrap" as const,
    padding: "0",
};

const innerContainerStyle = {
    display: "inline-flex",
    flexWrap: "wrap" as const,
    margin: 0,
    padding: 0,
    width: "100%",
    overflowY: "scroll" as const,
    minHeight: "70px",
};

const labelStyle = {
    height: "25px",
    display: "inline-flex",
    flexWrap: "wrap" as const,
    margin: "5px 5px",
    backgroundColor: "hsl(198,71.4%,53.3%)",
    color: "white",
    padding: "7px 15px",
};
const negativeLabelStyle = {
    height: "25px",
    display: "inline-flex",
    flexWrap: "wrap" as const,
    margin: "5px 5px",
    backgroundColor: "#f43",
    color: "white",
    padding: "7px 15px",
};
const inputstyle = {
    display: "inline-flex",
    outline: "none",
    border: "none",
    width: "100%",
    borderRadius: "3px",
};

export const Streams: FunctionComponent<IScreenProps> = () => {
    const streams = ["Release Candidate", "Early Visibility", "Canary", "FDM Dev"];

    const platforms = [
        {
            menuItem: "GCP Platform Installer",
            platform: "gcp_platform_installer",
        },
        {
            menuItem: "PolyJet Job Manager Installer",
            platform: "polyjet_job_manager_installer",
        },
        {
            menuItem: "PolyJet Cyclone Installer",
            platform: "polyjet_cyclone_installer",
        },
        {
            menuItem: "PolyJet Printer Software Installer",
            platform: "polyjet_printer_software_installer",
        },
    ];

    const renderStreamTab = (users: UserStream[], stream: string): JSX.Element => (
        <>
            <InfoBox className="qa-inviteuserslabel">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                In order to update user's stream, put email in proper tab.
            </InfoBox>
            <Tab
                menu={{ secondary: true, pointing: true }}
                panes={platforms.map((p) => {
                    const actualUsers = users.filter((u) => u.platform === p.platform);
                    return {
                        menuItem: `${p.menuItem} (${actualUsers.length})`,
                        render: () => renderPlatform(actualUsers, p.platform, stream),
                    };
                })}
            />
        </>
    );

    const renderPlatform = (users: UserStream[], platform: string, stream: string): JSX.Element => {
        return <Platform users={users} platform={platform} stream={stream} />;
    };

    return (
        <Query<ListUsersWithStreamsResult> query={LIST_USERS_WITH_STREAMS}>
            {({ loading, error, data }: QueryResult<ListUsersWithStreamsResult>) => {
                if (error) {
                    Notifier.error(error);
                    return null;
                }
                if (loading || !data) {
                    return <div className="ui loader active" />;
                }

                const { users } = data.listUsersWithStreams;
                return (
                    <div style={{ paddingBottom: 25 }}>
                        <Breadcrumbs sections={[{ label: "Streams" }]} />
                        <h2 className="page-header">Users assigned to streams</h2>
                        <h2>ATTENTION: Can break user or company workflows - be very careful</h2>
                        <p>
                            If you are putting a user onto a stream - then <b>ensure</b> that you
                            put them on the same stream for <b>all</b> products, failure to do this
                            can result in breaking a user or even worse a company workflow.
                        </p>
                        <p>
                            This is because the version of job manager etc are tied to a version of
                            GrabCAD Print, so a user who might receive print v 1.2.3.4 should also
                            receive a compatible (paired) version of Job Manager etc (v31.2...), if
                            they get a version mismatch it means that they cannot print to PJ
                            printers.
                        </p>
                        <Tab
                            menu={{ secondary: true, pointing: true, className: "qa-streamsTabs" }}
                            panes={streams.map((stream) => {
                                const acutalUsers = users.filter((u) => u.streamName === stream);
                                return {
                                    menuItem: `${stream} (${acutalUsers.length})`,
                                    render: () => renderStreamTab(acutalUsers, stream),
                                };
                            })}
                        />
                    </div>
                );
            }}
        </Query>
    );
};

export const Platform: FunctionComponent<IPlatformProps> = (props) => {
    const [emailsString, setEmailsString] = useState("");
    const [emailsInputString, setEmailsInputString] = useState("");
    const [streamMutation] = useMutation(UPDATE_USER_STREAM);
    const [deleteMutation] = useMutation(DELETE_USER_STREAM);

    useEffect(() => {
        setEmailsString(props.users.map((u) => u.user).join(" "));
    }, [props.users]);

    const removeEmailTag = (i: any) => {
        const newTags = parseEmails(emailsString);
        newTags.splice(i, 1);
        setEmailsString(newTags.join(" "));
    };

    const getEmailTag = (i: any) => {
        const tags = parseEmails(emailsString);
        return tags[i];
    };

    const updateEmailInput = (input: string) => {
        if (input.length - emailsInputString.length > 1) {
            // Assume the user pasted, check for emails after sanitizing input
            // If any exist, only retain valid emails
            // If none exist, fallback to default behavior
            const sanitizedInput = input.replace(/[<>;,]/g, "");
            const emails = parseEmails(sanitizedInput).filter((email) => validate(email));
            if (emails.length > 0) {
                setEmailsString(`${emailsString} ${emails}`);
                setEmailsInputString("");
                emails.forEach((email) => updateOrCreateStream(email));
                return;
            }
        }
        setEmailsInputString(input);
    };

    const inputKeyDown = (event: any): any => {
        const val = event.target.value;
        if (
            (event.key === " " || event.key === "Spacebar" || event.type === "blur") &&
            val.length > 1
        ) {
            setEmailsString(`${emailsString} ${emailsInputString}`);
            setEmailsInputString("");
            updateOrCreateStream(emailsInputString);
        } else if (event.key === "Backspace" && !val) {
            const i = emailsInputString.length - 1;
            deleteStream(getEmailTag(i));
            removeEmailTag(i);
        } else if (
            event.key === "Enter" &&
            parseEmails(emailsString).filter((email) => validate(email))
        ) {
            event.preventDefault();
            setEmailsString(`${emailsString} ${emailsInputString}`);
            setEmailsInputString("");
            updateOrCreateStream(emailsInputString);
        }
    };

    const updateOrCreateStream = (email: string): void => {
        void streamMutation({
            variables: {
                user: email,
                platform: props.platform,
                streamName: props.stream,
            },
            update: () => {
                Notifier.success(`Success!`);
            },
            onError: (error) => {
                Notifier.error(error);
            },
            refetchQueries: [{ query: LIST_USERS_WITH_STREAMS }],
        });
    };

    const deleteStream = (email: string) => {
        if (!email) {
            return;
        }

        void deleteMutation({
            variables: {
                user: email,
                platform: props.platform,
            },
            update: () => {
                Notifier.success(`${email} deleted`);
            },
            onError: (error) => {
                Notifier.error(error);
            },
            refetchQueries: [{ query: LIST_USERS_WITH_STREAMS }],
        });
    };

    return (
        <Form>
            <FormField>
                <div style={outerContainerStyle}>
                    <div style={innerContainerStyle}>
                        {parseEmails(emailsString).map((tag, i) =>
                            tag && validate(tag) ? (
                                <Label key={tag} style={labelStyle}>
                                    {tag}
                                    <Icon
                                        name="delete"
                                        onClick={() => {
                                            deleteStream(getEmailTag(i));
                                            removeEmailTag(i);
                                        }}
                                    />
                                </Label>
                            ) : (
                                <Label key={tag} style={negativeLabelStyle}>
                                    {tag}
                                    <Icon
                                        name="delete"
                                        onClick={() => {
                                            deleteStream(getEmailTag(i));
                                            removeEmailTag(i);
                                        }}
                                    />
                                </Label>
                            )
                        )}
                        <div style={{ background: "none", flexGrow: 1, padding: 0 }}>
                            <input
                                className="qa-streams-addUsersField"
                                placeholder={
                                    emailsString.length > 1
                                        ? ""
                                        : "email@domain.com, email2@domain.com..."
                                }
                                onKeyDown={inputKeyDown}
                                onBlur={inputKeyDown}
                                style={inputstyle}
                                value={emailsInputString}
                                onChange={(event) => updateEmailInput(event.currentTarget.value)}
                            />
                        </div>
                    </div>
                </div>
            </FormField>
        </Form>
    );
};
