import { Component, FormEvent, FunctionComponent, useContext } from "react";
import { produce } from "immer";
import gql from "graphql-tag";
import { Mutation, Query } from "@apollo/client/react/components";
import { MutationFunction, MutationResult, PureQueryOptions, QueryResult } from "@apollo/client";
import classnames from "classnames";
import { IScreenProps } from "../../screens/Screen";
import styled, {
    Button,
    Checkbox,
    Form,
    FormField,
    Input,
    Popup,
    TextArea,
} from "grabcad-ui-elements";
import {
    GET_COMPANY_AS_ADMIN,
    LicenseData,
    LicensePackage,
    LicenseState,
    MachineEntitlementData,
    MachineEntitlementRowState,
    MaterialEntitlementRowState,
    stringToPrinterVendorType,
} from "../../graphql";
import { match, Redirect, useLocation } from "react-router-dom";
import { deepEquals, identifyDuplicates, IsEqualFn } from "../../utils/form";
import { Notifier } from "../../view/Notifier";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { LicensePackageDropdown } from "./LicensePackageDropdown";
import { FormattedDatePicker } from "./FormattedDatePicker";
import moment from "moment-timezone";
import {
    GET_LICENSE,
    GetLicenseQuery,
    GetLicenseQueryResult,
    GetLicenseQueryVariables,
} from "../../graphql/Queries/Licenses/GetLicense";
import { EditMachineEntitlementList } from "../License/MachineEntitlementList";
import { MaterialEntitlementList } from "../Material/MaterialEntitlementList";
import { ApplicationContext, type TranslationFunction } from "../../components/ApplicationProvider";
import { isWhitespace, isWildcard } from "../../components/FormUtils";
import { machineEntitlementMatchesAny, materialMatchesAny } from "../../utils/license_utils";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";
import { NotifyAdminsCheckBox } from "../Company/NotifyAdminsCheckBox";
import { GET_COMPANY_LICENSES } from "../../graphql/Queries/Licenses/GetCompanyLicenses";
import { LicenseFragments } from "../../graphql/Fragments/License";
import { LIST_PRODUCTS_WITH_COMPANIES_AND_PACKAGES } from "../../graphql/Queries/Products/ListProducts";
import { LIST_COMPANIES } from "../../graphql/Queries/Companies/ListCompanies";
import { LIST_PACKAGES } from "./LicensePackageList";

export const CREATE_LICENSE = gql`
    mutation createLicense(
        $quantity: Int!
        $startDate: DateTime!
        $endDate: DateTime
        $licensePackageId: Int!
        $companyId: Int!
        $orderDate: DateTime
        $poNumber: String
        $orderId: String
        $orderLineNumber: String
        $partNumber: String
        $externalId: String
        $resellerSale: Boolean
        $comments: String
        $machineEntitlements: [MachineEntitlementCreateInput!]
        $materialEntitlements: [MaterialEntitlementCreateInput!]
        $renewedLicenseId: Int
        $notifyAdmins: Boolean
    ) {
        createLicense(
            quantity: $quantity
            orderDate: $orderDate
            startDate: $startDate
            endDate: $endDate
            licensePackageId: $licensePackageId
            companyId: $companyId
            poNumber: $poNumber
            orderId: $orderId
            orderLineNumber: $orderLineNumber
            partNumber: $partNumber
            externalId: $externalId
            resellerSale: $resellerSale
            comments: $comments
            machineEntitlements: $machineEntitlements
            materialEntitlements: $materialEntitlements
            renewedLicenseId: $renewedLicenseId
            notifyAdmins: $notifyAdmins
        ) {
            ...licenseFields
            ...additionalLicenseFields
        }
    }
    ${LicenseFragments.licenseFields}
    ${LicenseFragments.additionalLicenseFields}
`;

export const UPDATE_LICENSE = gql`
    mutation updateLicense(
        $id: Int!
        $quantity: Int!
        $startDate: DateTime!
        $endDate: DateTime
        $orderDate: DateTime
        $poNumber: String
        $orderId: String
        $orderLineNumber: String
        $partNumber: String
        $externalId: String
        $resellerSale: Boolean
        $comments: String
        $machineEntitlements: [MachineEntitlementUpdateInput!]
        $materialEntitlements: [MaterialEntitlementUpdateInput!]
    ) {
        updateLicense(
            id: $id
            data: {
                quantity: $quantity
                startDate: $startDate
                endDate: $endDate
                orderDate: $orderDate
                poNumber: $poNumber
                orderId: $orderId
                orderLineNumber: $orderLineNumber
                partNumber: $partNumber
                externalId: $externalId
                resellerSale: $resellerSale
                comments: $comments
                machineEntitlements: $machineEntitlements
                materialEntitlements: $materialEntitlements
            }
        ) {
            ...licenseFields
            ...additionalLicenseFields
        }
    }
    ${LicenseFragments.licenseFields}
    ${LicenseFragments.additionalLicenseFields}
`;

const StyledForm = styled(Form)`
    &.ui.form {
        max-width: 1018px;
        .row {
            display: flex;
            column-gap: 30px;
        }
        .field {
            flex: 1 1 0;
            &:last-child {
                margin-bottom: 1em;
            }
        }
    }
`;

// MachineEntitlements are considered equal if the serial number is the same, or if it is null and the other fields are the same
export const areMachineEntitlementsEqual: IsEqualFn<MachineEntitlementRowState> = (p1, p2) =>
    (!!p1.serial && p1.serial === p2.serial) ||
    (!p1.serial && !p2.serial && p1.technology === p2.technology && p1.model === p2.model);

// Material uniqueness is determined by vendor and name
export const areMaterialsEqual: IsEqualFn<MaterialEntitlementRowState> = (mat1, mat2) =>
    mat1.technology === mat2.technology && mat1.name === mat2.name;

function getLicenseMaxUsersValue(license: LicenseState): string {
    const licensePackage = license.package;
    if (!licensePackage) {
        return "";
    }
    if (licensePackage.unlimitedMaxUsers) {
        return "Unlimited";
    }
    return `${(license.quantity || 0) * (licensePackage.maxUsers || 0)}`;
}

interface ICreateLicenseProps extends IScreenProps {
    match: match<{ companyId?: string }>;
}
export const CreateLicense: FunctionComponent<ICreateLicenseProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    let companyId = parseInt(props.match.params.companyId!, 10);
    const search = useLocation().search;
    const licenseId = new URLSearchParams(search).get("renewFrom");

    if (licenseId) {
        // TODO GC-93984: Kill this Query in favor of GET_COMPANY_LICENSES
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { data: oldLicense, loading } = useQueryWithCatch<
            GetLicenseQueryResult,
            GetLicenseQueryVariables
        >(GET_LICENSE, {
            variables: {
                id: parseInt(licenseId ?? "", 10),
            },
        });
        return loading ? (
            <div className="ui loader active" />
        ) : (
            <LicenseForm
                mutation={CREATE_LICENSE}
                refetchQueries={[{ query: GET_COMPANY_LICENSES, variables: { companyId } }]}
                {...props}
                oldLicenseData={oldLicense?.license}
                t={t}
            />
        );
    } else {
        return (
            <LicenseForm
                mutation={CREATE_LICENSE}
                refetchQueries={[{ query: GET_COMPANY_LICENSES, variables: { companyId } }]}
                {...props}
                t={t}
            />
        );
    }
};

interface LicenseFormField {
    id: keyof LicenseState;
    required?: boolean;
    invalidator?: (string: any) => string | undefined;
    inputComponent?: string;
    label: string;
    type?: string;
    placeholder?: string;
    render?: (any: any) => string;
    parse?: (input: string) => any;
    disabled?: boolean;
}

interface IEditLicenseProps extends IScreenProps {
    match: match<{ companyId?: string }>;
}

export const EditLicense: FunctionComponent<IEditLicenseProps> = (props) => {
    const { t } = useContext(ApplicationContext);
    const licenseId = parseInt((props as any).match.params.id, 10);
    return (
        <GetLicenseQuery variables={{ id: licenseId }}>
            {({
                loading,
                error,
                data,
            }: QueryResult<GetLicenseQueryResult, GetLicenseQueryVariables>) => {
                if (error) {
                    Notifier.error(error);
                    return <Redirect to="/company" />;
                }
                if (loading || !data) {
                    return <div className="ui loader active" />;
                }

                return (
                    <div>
                        <Breadcrumbs sections={[{ label: t("license.breadcrumb") }]} />{" "}
                        <LicenseForm
                            license={data.license}
                            mutation={UPDATE_LICENSE}
                            editing={true}
                            t={t}
                            {...props}
                        />
                    </div>
                );
            }}
        </GetLicenseQuery>
    );
};

export interface ILicenseFormProps extends IScreenProps {
    match: match<{ companyId?: string }>;
    mutation: any;
    /** Specified for edit license; else omit for create license */
    license?: LicenseData;
    refetchQueries?: (string | PureQueryOptions)[];
    editing?: boolean;
    t: TranslationFunction;
    oldLicenseData?: LicenseData;
}

export interface ILicenseFormState {
    license: LicenseState;

    dirty: boolean;
    submitClicked: boolean;
    companyId: number | undefined;
    subscriptionExternalKey: number | undefined;

    /**
     * MachineEntitlementList indicates match any machineEntitlement.
     * This is for features with isMachineEntitlementType=true, we can indicate that _any_ machineEntitlement is acceptable.
     * This also makes it easier to create "superuser" licenses, which allow developers to easily access a feature entitled for all machineEntitlements.
     */
    isMatchAnyMachineEntitlement: boolean;

    isMatchAnyMaterial: boolean;

    isRenewing: boolean;

    notifyAdmins: boolean;
}

export class LicenseForm extends Component<ILicenseFormProps, ILicenseFormState> {
    static contextType = ApplicationContext;
    context!: React.ContextType<typeof ApplicationContext>;

    getQueryParam = (search: string, key: string): string | null =>
        new URLSearchParams(search).get(key);

    renderLicenseInViewMode = (): boolean => {
        const { search } = useLocation();
        return this.getQueryParam(search, "mode") === "view";
    };

    partNumberField: LicenseFormField = {
        id: "partNumber",
        label: "Part Number",
        required: this.isCreatingLicense(),
    };
    externalIdField: LicenseFormField = {
        id: "externalId",
        label: "External Key",
        required: true,
        disabled: false,
    };
    orderIdField: LicenseFormField = {
        id: "orderId",
        label: "Order ID",
    };
    orderLineNumberField: LicenseFormField = {
        id: "orderLineNumber",
        label: "Order Line Number",
    };
    purchaseOrderNumberField: LicenseFormField = {
        id: "poNumber",
        label: "PO #",
    };
    notesField: LicenseFormField = {
        id: "comments",
        label: "Notes",
        inputComponent: "textArea",
    };

    constructor(props: ILicenseFormProps) {
        super(props);
        let companyId: number | undefined = parseInt((props as any).match.params.companyId, 10);
        if (!companyId) {
            companyId = props.license?.companyId; // When editing a license the companyId is no longer in the URL
        }

        const license: LicenseState = props.license
            ? // edit license => fill in all GQL fetch values
              props.license
            : // create license => Boot strap with some temporary until LicensePackageDropdown fills in more substantive values
              {
                  companyId,
                  quantity: 1,
                  startDate: new Date(),
                  orderDate: new Date(),
              };

        const { oldLicenseData } = props;
        if (oldLicenseData) {
            this.copyOverRequiredLicenseData(oldLicenseData, license);
        }

        const initialLicense = produce(license, (licenseDraft) => {
            if (licenseDraft.materialEntitlements) {
                licenseDraft.materialEntitlements = licenseDraft.materialEntitlements.map(
                    (mat) => ({
                        id: mat.id,
                        technology: mat.technology,
                        name: mat.name,
                    })
                );
            }

            licenseDraft.materialEntitlements = this.markMaterialDuplicates(
                licenseDraft.materialEntitlements
            );
            licenseDraft.machineEntitlements = this.markMachineEntitlementDuplicates(
                licenseDraft.machineEntitlements
            );
        });

        this.state = {
            license: initialLicense,
            dirty: false,
            submitClicked: false,
            companyId: companyId,
            subscriptionExternalKey: undefined,
            isMatchAnyMachineEntitlement: !!(
                props?.license?.machineEntitlements?.length &&
                machineEntitlementMatchesAny(props?.license?.machineEntitlements[0])
            ),
            isMatchAnyMaterial: !!(
                props.license?.materialEntitlements &&
                props.license.materialEntitlements.length === 1 &&
                materialMatchesAny(props.license.materialEntitlements[0])
            ),
            isRenewing: !!this.props.oldLicenseData,
            notifyAdmins: true,
        };

        // Calling produce() with a no-op updater function will still freeze this.state
        // Without this call, state is not frozen until the first call to produce()
        produce(this.state, () => {});
    }

    private copyOverRequiredLicenseData(oldLicenseData: LicenseData, license: LicenseState) {
        license.package = oldLicenseData.package;
        license.renewedLicenseId = oldLicenseData.id; // this is important as we use this to hot swap groups
        license.licensePackageId = oldLicenseData.package.id;
        license.partNumber = oldLicenseData.package.partNumber ?? oldLicenseData.partNumber;
        license.externalId = oldLicenseData.externalId;
        license.timeSpan = oldLicenseData.timeSpan ?? 1;
        license.comments = oldLicenseData.comments ?? "";
        license.quantity = oldLicenseData.quantity;
        license.orderId = oldLicenseData.orderId;
        license.orderLineNumber = oldLicenseData.orderLineNumber;

        const duration = oldLicenseData.package.duration;
        let endDate = new Date();
        if (duration) {
            endDate = new Date(
                moment(license.startDate)
                    .add(license.timeSpan, duration === "yearly" ? "year" : "month")
                    .toISOString()
            );
        }
        license.endDate = endDate;

        if (!!oldLicenseData.machineEntitlements) {
            license.machineEntitlements = oldLicenseData.machineEntitlements.map(
                (machineEntitlement) => {
                    return {
                        serial: machineEntitlement.serial,
                        model: machineEntitlement.model,
                        technology: machineEntitlement.technology,
                    };
                }
            );
        }

        if (!!oldLicenseData.materialEntitlements) {
            license.materialEntitlements = oldLicenseData.materialEntitlements.map(
                (materialEntitlement) => {
                    return {
                        technology: materialEntitlement.technology,
                        name: materialEntitlement.name,
                    };
                }
            );
        }
    }

    markMachineEntitlementDuplicates(
        machineEntitlements?: MachineEntitlementRowState[]
    ): MachineEntitlementRowState[] | undefined {
        if (!machineEntitlements?.length) {
            return machineEntitlements;
        }

        const duplicates = identifyDuplicates(machineEntitlements, areMachineEntitlementsEqual);
        return machineEntitlements.map((machineEntitlement, i) => ({
            ...machineEntitlement,
            isDuplicate: duplicates[i],
        }));
    }

    markMaterialDuplicates(
        materialEntitlements?: MaterialEntitlementRowState[]
    ): MaterialEntitlementRowState[] | undefined {
        if (!materialEntitlements) {
            return materialEntitlements;
        }

        const duplicates = identifyDuplicates(materialEntitlements, areMaterialsEqual);
        return materialEntitlements.map((mat, i) => ({
            ...mat,
            isDuplicate: duplicates[i],
        }));
    }

    getMachineEntitlementDataFromMachineEntitlementRows(
        scopeRows: MachineEntitlementRowState[]
    ): MachineEntitlementData[] {
        // Strip extraneous fields returned by PrinterGroup query
        return scopeRows.map((scope: MachineEntitlementRowState): MachineEntitlementData => {
            return {
                id: scope.id,
                serial: scope.serial,
                model: scope.model,
                technology: scope.technology,
            };
        });
    }

    isCreatingLicense(): boolean {
        return this.props.mutation === CREATE_LICENSE;
    }

    validatePackage(): boolean {
        return this.isCreatingLicense() ? !!this.state.license.licensePackageId : true;
    }

    validateLicenseDates(): boolean {
        return (
            this.state.license.startDate !== undefined && this.state.license.endDate !== undefined
        );
    }

    validateMachines(): boolean {
        if (!this.isShowMachineForm()) {
            // no machines => validation passes
            return true;
        }
        const machineEntitlements = this.state.license.machineEntitlements;
        // OK if "any machine" button is active
        if (this.state.isMatchAnyMachineEntitlement) {
            return true;
        }
        // Must have at least one entry
        if (!machineEntitlements?.length) {
            return false;
        }
        // Can't be all unset (in the UI at least)
        if (
            machineEntitlements?.some(
                (s) => isWhitespace(s.serial) && isWhitespace(s.technology) && isWhitespace(s.model)
            )
        ) {
            return false;
        }
        // Can't be literal wildcard
        if (
            machineEntitlements?.some(
                (s) => isWildcard(s.serial) || isWildcard(s.technology) || isWildcard(s.model)
            )
        ) {
            return false;
        }
        // Must be valid technology (this is just for UI input - the field itself is not actually enumerated any more)
        if (
            machineEntitlements?.some(
                (s) => s.technology && !stringToPrinterVendorType(s.technology)
            )
        ) {
            return false;
        }
        // Can't be duplicate
        return !machineEntitlements?.some((s) => s.isDuplicate);
    }

    validateMaterials(): boolean {
        if (!this.isShowMaterialForm()) {
            // no materialEntitlements => validation passes
            return true;
        }
        const materialEntitlements: MaterialEntitlementRowState[] | undefined =
            this.state.license.materialEntitlements;
        return (
            this.state.isMatchAnyMaterial ||
            (!!materialEntitlements &&
                materialEntitlements.length !== 0 &&
                materialEntitlements.every(
                    (m) =>
                        stringToPrinterVendorType(m.technology) &&
                        !isWhitespace(m.name) &&
                        !m.isDuplicate
                ))
        );
    }

    validate() {
        const simpleFields = [this.partNumberField, this.notesField, this.externalIdField] as any;
        const simpleFieldsValid = !simpleFields.some(
            (field: LicenseFormField) => field.required && !this.state.license[field.id]
        );

        return (
            simpleFieldsValid &&
            this.validateLicenseDates() &&
            this.validatePackage() &&
            this.validateMachines() &&
            this.validateMaterials()
        );
    }

    isShowMaterialForm(): boolean {
        return !!this.state.license.package?.product?.features?.find((f) => f?.isMaterialType);
    }

    isShowMachineForm(): boolean {
        return !!this.state.license.package?.product?.features?.find((f) => f?.isPrinterType);
    }

    updateField(
        id: keyof LicenseState,
        event: FormEvent<HTMLInputElement>,
        isNumber?: boolean,
        fieldType?: string
    ) {
        let { value } = event.target as HTMLInputElement;

        const nextLicense = produce(this.state.license, (license) => {
            (license[id] as string | number) = isNumber
                ? fieldType === "externalId"
                    ? value.replace(/[^0-9]/g, "").slice(0, 15)
                    : Number(value)
                : value;
        });

        this.setState({ license: nextLicense });
    }

    generateExternalKey() {
        const timestamp = Date.now().toString().slice(-9); // Get the last 9 digits of the timestamp
        const randomValue = Math.floor(Math.random() * 1000)
            .toString()
            .padStart(3, "0"); // 3-digit random value, padded to 3 digits
        const uniqueNumber = timestamp + randomValue;
        const nextLicense = produce(this.state.license, (license) => {
            license.externalId = uniqueNumber;
        });
        this.setState({ license: nextLicense });
    }

    render() {
        const { t } = this.context;

        const inputComponents: { [id: string]: any } = {
            input: Input,
            textArea: TextArea,
        };

        const getSimpleField = (
            field: LicenseFormField,
            isNumber: boolean,
            defaultValue?: string
        ) => {
            const InputClass = inputComponents[field.inputComponent || "input"];
            const invalid: boolean =
                this.state.submitClicked && !!(field.required && !this.state.license[field.id]);
            const disabled =
                this.renderLicenseInViewMode() || // Disable the field if in view mode
                (field.disabled !== undefined ? field.disabled : !this.isCreatingLicense());
            return (
                <FormField key={field.id}>
                    <label>
                        {field.label}
                        {field.required && <span style={{ color: "red" }}>&nbsp;*</span>}
                    </label>
                    <InputClass
                        id={`qa-license-field_${field.id}`}
                        data-test-id={`qa-license-field_${field.id}`}
                        className={`qa-license-field ${classnames({ error: invalid })}`}
                        autoComplete={"off"}
                        value={(this.state.license[field.id] as string) || defaultValue || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.updateField(field.id, e, isNumber, field.id)
                        }
                        type={field.type}
                        disabled={disabled}
                    />
                    {invalid && (
                        <p className="ui negative message">
                            This field is required. {/* i18n("forms.field_required") */}
                        </p>
                    )}
                </FormField>
            );
        };

        const addDurationToDate = (
            startDate: Date | undefined,
            duration?: string | null,
            amount: number = 1
        ) => {
            if (duration === "monthly") {
                return new Date(moment(startDate).add(amount, "month").toISOString());
            }
            if (duration === "yearly") {
                return new Date(moment(startDate).add(amount, "year").toISOString());
            }
            return null;
        };

        const getDefaultEndDate = (license: any) => {
            if (license.package?.duration) {
                let startDate = license.startDate;
                if (!startDate) {
                    startDate = new Date();
                }
                return addDurationToDate(startDate, license.package.duration);
            }
            return null;
        };

        const getDefaultTimeSpan = (license: LicenseState) => {
            if (license.package?.duration) {
                if (license.package.duration === "monthly") {
                    return moment(license.endDate).diff(license.startDate, "months");
                }
                if (license.package.duration === "yearly") {
                    return moment(license.endDate).diff(license.startDate, "years");
                }
                return 1;
            }
            return undefined;
        };

        const getQuantityField = () => {
            return (
                <FormField>
                    <label>
                        Quantity
                        <span style={{ color: "red" }}>&nbsp;*</span>{" "}
                    </label>
                    <Input
                        type="number"
                        min="1"
                        max="1000"
                        value={this.state.license.quantity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const nextLicense = produce(this.state.license, (license) => {
                                let { min, max, value } = e.target;
                                license.quantity = Math.min(
                                    Number(max),
                                    Math.max(Number(min), Number(value))
                                );
                            });

                            this.setState({ license: nextLicense });
                        }}
                        disabled={
                            !this.isCreatingLicense() ||
                            this.state.license.package?.unlimitedMaxUsers ||
                            this.state.isRenewing
                        }
                    />
                </FormField>
            );
        };
        // FIXME GC-70984 Tech debt: Match this React component like <MaterialEntitlementList>
        const getMachineForm = () => {
            // When selecting API license, show machine input form
            const newMachineEntitlement: MachineEntitlementRowState = {
                serial: null,
                model: null,
                technology: null,
                isPlaceholder: true,
            };
            const onChange = (
                index: number,
                fieldName: keyof MachineEntitlementRowState,
                e: React.ChangeEvent<HTMLInputElement>
            ) => {
                const nextLicense = produce(this.state.license, (license) => {
                    if (!license.machineEntitlements?.length) {
                        // Editing machine that does not yet exist because UI always show at least 1 placeholder item
                        // => auto-create new machine
                        license.machineEntitlements = [newMachineEntitlement];
                    }

                    const oldMachine: MachineEntitlementRowState = {
                        ...license.machineEntitlements[index],
                    };

                    (license.machineEntitlements[index][fieldName] as string) = e.target.value;
                    license.machineEntitlements[index].isPlaceholder = false;

                    const newMachine: MachineEntitlementRowState =
                        license.machineEntitlements[index];

                    // Only recompute machine duplicate flags if identifying fields have changed
                    if (!areMachineEntitlementsEqual(oldMachine, newMachine)) {
                        license.machineEntitlements = this.markMachineEntitlementDuplicates(
                            license.machineEntitlements
                        );
                    }
                });

                this.setState({ license: nextLicense });
            };
            const onAddButtonPressed = () => {
                const nextLicense = produce(this.state.license, (license) => {
                    if (license?.machineEntitlements) {
                        license.machineEntitlements.push(newMachineEntitlement);
                    }
                });

                this.setState({ license: nextLicense });
            };
            const onDeleteButtonPressed = (index: number) => {
                const nextLicense = produce(this.state.license, (license) => {
                    if (license?.machineEntitlements) {
                        license.machineEntitlements.splice(index, 1);
                        license.machineEntitlements = this.markMachineEntitlementDuplicates(
                            license.machineEntitlements
                        );
                    }
                });

                this.setState({ license: nextLicense });
            };

            if (this.isShowMachineForm()) {
                return (
                    <EditMachineEntitlementList
                        title={"Machine"}
                        companyId={this.state.companyId}
                        machines={this.state.license?.machineEntitlements || []}
                        onChange={onChange}
                        onAddButtonPressed={onAddButtonPressed}
                        onDeleteButtonPressed={onDeleteButtonPressed}
                        isMatchAny={this.state.isMatchAnyMachineEntitlement}
                        toggleMatchAny={() => {
                            this.setState((prevState) => ({
                                isMatchAnyMachineEntitlement:
                                    !prevState.isMatchAnyMachineEntitlement,
                            }));
                        }}
                        isRenewing={this.state.isRenewing}
                        disabled={this.renderLicenseInViewMode()}
                    />
                );
            }
        };

        const expectedMachineProps: (keyof MachineEntitlementData)[] = ["serial", "model"];
        const machineEntitlementListIsDirty = (): boolean => {
            if (this.isShowMachineForm()) {
                let license = { ...this.state.license };
                if (license.machineEntitlements) {
                    for (const data of license.machineEntitlements) {
                        for (const machineProp of expectedMachineProps) {
                            if (data[machineProp] !== "") {
                                return true;
                            }
                        }
                    }
                }
                return false;
            }
            return true;
        };

        const licenseModified = !deepEquals(this.props.license, this.state.license);
        const machinesModified = machineEntitlementListIsDirty();
        const isDirty = licenseModified || machinesModified || !!this.props.oldLicenseData;
        const isValid = this.validate();

        const timeSpanLabelSuffix = () => {
            if (this.state.license?.package?.duration === "monthly") {
                return "(Months)"; // i18n("license.time_span_months")
            }
            if (this.state.license?.package?.duration === "yearly") {
                return "(Years)"; // i18n("license.time_span_years")
            }
            if (this.state.license?.package?.duration === null) {
                return "(N/A - eternal license)";
            }
            return "";
        };

        type MutateLicenseResult = { createLicense?: any; updateLicense?: any };

        return (
            <Mutation<MutateLicenseResult, LicenseState>
                mutation={this.props.mutation}
                refetchQueries={[
                    ...(this.props.refetchQueries ?? []),
                    { query: LIST_PRODUCTS_WITH_COMPANIES_AND_PACKAGES },
                    { query: LIST_PACKAGES },
                    { query: LIST_COMPANIES },
                ]}
                update={(_cache, { data }) => {
                    if (!data) {
                        // Possible there there are errors?
                        return;
                    }
                    const { createLicense, updateLicense } = data;
                    this.props.history.push(`/company/${this.state.companyId}`);
                    if (createLicense) {
                        Notifier.success("Successfully created license.");
                    } else if (updateLicense) {
                        Notifier.success("Successfully updated license.");
                        this.setState({ submitClicked: false });
                    }
                }}
                onError={(error) => Notifier.error(error)}
            >
                {(
                    update: MutationFunction<MutateLicenseResult, LicenseState>,
                    { loading }: MutationResult<MutateLicenseResult>
                ) => (
                    <StyledForm
                        onSubmit={(event: FormEvent<HTMLFormElement>) => {
                            event.preventDefault();

                            const nextState = produce(this.state, (state) => {
                                const license = state.license;
                                license.externalId =
                                    license?.externalId?.toString() || license.externalId;
                                if (this.state.isMatchAnyMachineEntitlement) {
                                    // ignore anything in license.machineEntitlements
                                    license.machineEntitlements = [
                                        {
                                            serial: null,
                                            technology: null,
                                            model: null,
                                        },
                                    ];
                                } else if (license.machineEntitlements) {
                                    // Must echo MachineEntitlementGroup back to server so that we don't accidentally delete stuff
                                    license.machineEntitlements =
                                        this.getMachineEntitlementDataFromMachineEntitlementRows(
                                            license.machineEntitlements
                                        );
                                }

                                if (this.state.isMatchAnyMaterial) {
                                    license.materialEntitlements = [
                                        {
                                            name: null,
                                            technology: null,
                                        },
                                    ];
                                } else if (license.materialEntitlements) {
                                    license.materialEntitlements = license.materialEntitlements.map(
                                        (material) => {
                                            const { isDuplicate, isPlaceholder, ...keepFields } =
                                                material;
                                            return keepFields;
                                        }
                                    );
                                }

                                state.submitClicked = true;
                            });

                            this.setState(nextState);

                            const vars = this.isCreatingLicense()
                                ? { ...nextState.license, notifyAdmins: nextState.notifyAdmins }
                                : { ...nextState.license };

                            if (isValid && !loading) {
                                update({ variables: vars }).catch((err) => Notifier.error(err));
                            }
                        }}
                    >
                        <div className="row">
                            <FormField>
                                <Query
                                    query={GET_COMPANY_AS_ADMIN}
                                    variables={{ id: this.state.companyId }}
                                >
                                    {({ loading: isLoading, error, data }: QueryResult) => {
                                        if (error) {
                                            Notifier.error(error);
                                            return <Redirect to="/" />;
                                        }
                                        if (isLoading || !data) {
                                            return <div className="ui loader active" />;
                                        }
                                        const company = { ...data.company };
                                        return (
                                            <>
                                                <label>{t("license.company_name")}</label>{" "}
                                                <Input value={company.name} disabled={true} />
                                            </>
                                        );
                                    }}
                                </Query>
                            </FormField>
                            <FormField style={{ flexGrow: 2, flexBasis: 30 }}>
                                <LicensePackageDropdown
                                    licensePackageId={
                                        this.state.license.package
                                            ? this.state.license.package.id
                                            : 0
                                    }
                                    onChange={(licensePackageJson) => {
                                        const nextLicense = produce(
                                            this.state.license,
                                            (license) => {
                                                const licensePackage: LicensePackage =
                                                    JSON.parse(licensePackageJson);
                                                license.licensePackageId = licensePackage.id;
                                                license.package = licensePackage;
                                                // pre-populate license with data from license package
                                                license.partNumber = licensePackage.partNumber;
                                                license.quantity = 1;
                                                license.endDate = getDefaultEndDate(license);
                                                if (license.timeSpan) {
                                                    license.endDate = addDurationToDate(
                                                        license.startDate,
                                                        license.package.duration,
                                                        license.timeSpan
                                                    );
                                                }
                                            }
                                        );

                                        this.setState({ license: nextLicense });
                                    }}
                                    disabled={!this.isCreatingLicense()}
                                    invalid={
                                        this.state.submitClicked &&
                                        this.isCreatingLicense() &&
                                        !!this.props.license?.package
                                    }
                                />
                            </FormField>
                        </div>
                        <div className="row">
                            <FormField>
                                <label>Order Date</label>
                                <FormattedDatePicker
                                    id="qa-license-datePicker-orderDate"
                                    value={
                                        this.state.license.orderDate
                                            ? moment(this.state.license.orderDate).toISOString()
                                            : new Date().toISOString()
                                    }
                                    onDateChange={(date) => {
                                        if (!date) {
                                            return;
                                        }

                                        const nextLicense = produce(
                                            this.state.license,
                                            (license) => {
                                                license.orderDate = date.toDate();
                                            }
                                        );

                                        this.setState({ license: nextLicense });
                                    }}
                                    disabled={!this.isCreatingLicense()}
                                />
                            </FormField>
                            <FormField>
                                <label>
                                    Start Date
                                    <span style={{ color: "red" }}>&nbsp;*</span>{" "}
                                </label>
                                <FormattedDatePicker
                                    id="qa-license-datePicker-startDate"
                                    value={
                                        this.state.license.startDate
                                            ? moment(this.state.license.startDate).toISOString()
                                            : new Date().toISOString()
                                    }
                                    onDateChange={(date) => {
                                        if (!date) {
                                            return;
                                        }

                                        const nextLicense = produce(
                                            this.state.license,
                                            (license) => {
                                                license.startDate = date.toDate();
                                                if (license.package) {
                                                    license.endDate = getDefaultEndDate(license);
                                                }
                                            }
                                        );

                                        this.setState({ license: nextLicense });
                                    }}
                                    disabled={!this.isCreatingLicense()}
                                />
                            </FormField>
                            <FormField>
                                <label>
                                    {t("license.end_date")}
                                    <span style={{ color: "red" }}>&nbsp;*</span>{" "}
                                </label>
                                <FormattedDatePicker
                                    id="qa-license-datePicker-endDate"
                                    value={
                                        this.state.license.endDate
                                            ? moment(this.state.license.endDate).toISOString()
                                            : getDefaultEndDate(this.state.license)?.toISOString()
                                    }
                                    onDateChange={(date) => {
                                        if (!date) {
                                            return;
                                        }

                                        const nextLicense = produce(
                                            this.state.license,
                                            (license) => {
                                                license.endDate = date.toDate();
                                            }
                                        );

                                        this.setState({ license: nextLicense });
                                    }}
                                    disabled={true}
                                />
                            </FormField>
                        </div>
                        <div className="row">
                            <FormField key="partNumber">
                                <label>Part Number</label>
                                <Input
                                    id={`qa-license-field_partNumber`}
                                    autoComplete={"off"}
                                    value={
                                        this.state.isRenewing
                                            ? this.state.license.partNumber
                                            : this.state.license.package
                                            ? this.state.license.package.partNumber
                                            : ""
                                    }
                                    disabled={true}
                                />
                            </FormField>
                            {getSimpleField(this.orderIdField, false)}
                            {getSimpleField(this.orderLineNumberField, false)}
                        </div>
                        <div className="row">
                            {getSimpleField(this.purchaseOrderNumberField, false)}
                            {getSimpleField(
                                this.externalIdField,
                                true,
                                this.state.subscriptionExternalKey?.toString()
                            )}
                            <FormField>
                                <Popup
                                    id="qa-generate-popup"
                                    data-test-id="qa-generate-popup"
                                    trigger={
                                        <Button
                                            id="qa-license-generate"
                                            data-test-id="qa-license-generate"
                                            type="button"
                                            onClick={() => this.generateExternalKey()}
                                            className="ui icon button"
                                            disabled={this.renderLicenseInViewMode()}
                                        >
                                            {t("forms.generate")}{" "}
                                            <i aria-hidden="true" className="info circle icon"></i>
                                        </Button>
                                    }
                                    content={t("forms.generate_key_warning")}
                                    position="right center"
                                />
                            </FormField>
                        </div>
                        <div className="row">
                            {getQuantityField()}
                            <FormField>
                                <label>Number of users</label>
                                <Input
                                    value={getLicenseMaxUsersValue(this.state.license)}
                                    disabled={true}
                                />
                            </FormField>
                            <FormField>
                                <label>
                                    Time Span {timeSpanLabelSuffix()}
                                    <span style={{ color: "red" }}>&nbsp;*</span>{" "}
                                </label>
                                <Input
                                    id="qa-license-timeSpan"
                                    data-test-id="qa-license-timeSpan"
                                    type="number"
                                    min="1"
                                    max="100"
                                    value={
                                        this.state.license.timeSpan
                                            ? this.state.license.timeSpan
                                            : getDefaultTimeSpan(this.state.license)
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const nextLicense = produce(
                                            this.state.license,
                                            (license) => {
                                                license.timeSpan = Number(e.target.value);
                                                if (license.package && license.startDate) {
                                                    license.endDate = addDurationToDate(
                                                        license.startDate,
                                                        license.package.duration,
                                                        license.timeSpan
                                                    );
                                                }
                                            }
                                        );

                                        this.setState({ license: nextLicense });
                                    }}
                                    disabled={
                                        this.state.license.package?.duration === null ||
                                        this.renderLicenseInViewMode()
                                    }
                                />
                            </FormField>
                        </div>
                        {getMachineForm()}
                        {this.isShowMaterialForm() && (
                            <LicenseMaterialForm
                                license={this.state.license}
                                setLicense={(license: LicenseState) => this.setState({ license })}
                                isMatchAny={this.state.isMatchAnyMaterial}
                                toggleMatchAny={() => {
                                    this.setState((prevState) => ({
                                        isMatchAnyMaterial: !prevState.isMatchAnyMaterial,
                                    }));
                                }}
                                isRenewing={this.state.isRenewing}
                            />
                        )}
                        <div className="row">{getSimpleField(this.notesField, false)}</div>
                        <FormField>
                            <Checkbox
                                id="qa-license-resellerSale"
                                label="Sale through reseller (This will send the reseller an email for this order)"
                                defaultChecked={!!this.state.license.resellerSale}
                                onChange={(e: React.FormEvent<HTMLInputElement>, data) => {
                                    const { checked } = data;
                                    const nextLicense = produce(this.state.license, (license) => {
                                        license.resellerSale = checked;
                                    });

                                    this.setState({ license: nextLicense });
                                }}
                                disabled={!this.isCreatingLicense()}
                            />
                        </FormField>
                        <FormField key="buttons">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        id="qa-license-cancel"
                                        type="button"
                                        secondary
                                        disabled={!isValid && this.state.submitClicked}
                                        onClick={() =>
                                            this.props.history.push(
                                                `/company/${this.state.companyId}`
                                            )
                                        }
                                    >
                                        Cancel {/* i18n("forms.cancel") */}
                                    </Button>
                                    <Button
                                        id="qa-license-submit"
                                        type="submit"
                                        className="primary right floated"
                                        disabled={
                                            !isDirty || !isValid || this.renderLicenseInViewMode()
                                        }
                                    >
                                        Submit {/* i18n("forms.submit") */}
                                    </Button>
                                    {this.isCreatingLicense() && (
                                        <NotifyAdminsCheckBox
                                            label={t("forms.notify_admins")}
                                            checked={this.state.notifyAdmins}
                                            onClick={() => {
                                                this.setState({
                                                    notifyAdmins: !this.state.notifyAdmins,
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </FormField>
                    </StyledForm>
                )}
            </Mutation>
        );
    }
}

const createNewMaterial = (): MaterialEntitlementRowState => ({
    technology: null,
    name: null,
    isPlaceholder: true,
});

const LicenseMaterialForm = ({
    license,
    setLicense,
    isMatchAny,
    toggleMatchAny,
    isRenewing,
}: {
    license: LicenseState;
    setLicense: (license: LicenseState) => void;
    isMatchAny: boolean;
    toggleMatchAny: () => void;
    isRenewing: boolean;
}) => {
    const { t } = useContext(ApplicationContext);

    const markDuplicates = (materialEntitlements: MaterialEntitlementRowState[]) => {
        const duplicates = identifyDuplicates(materialEntitlements, areMaterialsEqual);
        materialEntitlements.forEach((mat, i) => (mat.isDuplicate = duplicates[i]));
    };

    return (
        <MaterialEntitlementList
            isMatchAny={isMatchAny}
            toggleMatchAny={toggleMatchAny}
            onAddButtonPressed={() => {
                // Add new material to license state
                const nextLicense = produce(license, (licenseDraft) => {
                    licenseDraft.materialEntitlements = (
                        licenseDraft.materialEntitlements || []
                    ).concat([createNewMaterial()]);
                });

                setLicense(nextLicense);
            }}
            onDeleteButtonPressed={(index: number) => {
                // Delete material from license state.
                // When license is eventually submitted, material won't be echoed back, and will therefore be deleted.
                const nextLicense = produce(license, (licenseDraft) => {
                    const materialEntitlements =
                        (licenseDraft.materialEntitlements && [
                            ...licenseDraft.materialEntitlements,
                        ]) ||
                        [];
                    if (index < materialEntitlements.length) {
                        materialEntitlements.splice(index, 1);
                        licenseDraft.materialEntitlements = materialEntitlements;
                        markDuplicates(materialEntitlements);
                    }
                });

                setLicense(nextLicense);
                // else
                //  Ignore delete for item that does not exist [in data model].
                //  Possible because UI always show at least 1 placeholder item.
            }}
            materialEntitlements={license.materialEntitlements}
            title={t("materials.material")}
            readOnly={isRenewing}
            onChange={(num: number, fieldname: string, e: React.ChangeEvent<HTMLInputElement>) => {
                e.preventDefault();
                // NB: Can edit item that does not yet exist because UI always show at least 1 placeholder item
                const nextLicense = produce(license, (licenseDraft) => {
                    const materialEntitlements = licenseDraft.materialEntitlements || [];

                    let old: MaterialEntitlementRowState = materialEntitlements[num];
                    if (!old) {
                        old = createNewMaterial();
                        materialEntitlements[num] = old;
                    }
                    materialEntitlements[num] = {
                        ...old,
                        [fieldname]: e.target.value as any,
                        isPlaceholder: false,
                    };

                    // Only recompute material duplicate flags if identifying fields have changed
                    if (!areMaterialsEqual(old, materialEntitlements[num])) {
                        markDuplicates(materialEntitlements);
                    }

                    licenseDraft.materialEntitlements = materialEntitlements;
                });

                setLicense(nextLicense);
            }}
        />
    );
};
