import { Component, FunctionComponent, useContext, useState } from "react";
import styled, { Checkbox, Image } from "grabcad-ui-elements";
import { UserContext } from "../../components/User/UserProvider";
import {
    Role,
    PrinterVendorType,
    stringToPrinterVendorType,
    MachineEntitlementRowState,
} from "../../graphql/types";
import * as _ from "lodash";
import { ApplicationContext, IApplicationContext } from "../../components/ApplicationProvider";

import TrashIcon from "../../images/trash.svg";
import {
    DecoratedHeader,
    FieldPlaceholderValue,
    FormEditableField,
    FormEditableFieldProps,
    FormFieldMeta,
    isWildcard,
} from "../../components/FormUtils";
import { CompanyAsGlobalAdmin } from "../../graphql";

const NUM_COLUMNS = 5;
const TableData = styled.td`
    width: calc(100% / ${NUM_COLUMNS});
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 4px;

    :hover {
        background: #e0ecfc;
        fill: #f00;
        cursor: pointer;
    }
`;

export const EditMachineEntitlementList: FunctionComponent<IEditMachineEntitlementListProps> = (
    props
) => {
    const userContext = useContext(UserContext);
    return <MachineEntitlementList {...props} role={userContext.role} disabled={props.disabled} />;
};

// NB: Using MachineEntitlementState instead of MachineEntitlementData allows React component to render new Machine that do _not_ have yet have vendor/id
type MachineEntitlementState = Partial<MachineEntitlementRowState>;
type MachineFormField = FormFieldMeta<MachineEntitlementState>;

type OnMachineEntitlementChange = (
    index: number,
    fieldName: keyof MachineEntitlementState,
    e: React.ChangeEvent<HTMLInputElement>
) => void;
type OnDeleteRequest = (index: number) => void;

interface IEditMachineEntitlementListProps {
    title: string;
    machines: MachineEntitlementState[];
    onChange: OnMachineEntitlementChange;

    // Omit this if Add button should _not_ be shown
    onAddButtonPressed?: () => void;

    // Omit this if Delete button should _not_ be shown
    onDeleteButtonPressed?: OnDeleteRequest;
    /** whether or not to show UI that indicates any machineentitlement instead of "this.props.machines" */
    isMatchAny?: boolean;
    /** If truish, then show button to checkbox/toggle for isMatchAny. Else toggle is hidden. */
    toggleMatchAny?: () => void;
    isRenewing: boolean;
    companyId?: number;
    disabled?: boolean;
}

export interface IMachineEntitlementListProps extends IEditMachineEntitlementListProps {
    role: Role;
    company?: CompanyAsGlobalAdmin | undefined;
}

interface MachineFieldMetas {
    serialField: MachineFormField;
    modelField: MachineFormField;
    technologyField: MachineFormField;
}

export class MachineEntitlementList extends Component<IMachineEntitlementListProps> {
    static contextType = ApplicationContext;
    isGlobalAdmin: boolean;
    constructor(props: IMachineEntitlementListProps) {
        super(props);
        this.isGlobalAdmin = this.props.role === Role.GlobalAdmin;
    }
    fieldMetas: MachineFieldMetas = {
        serialField: {
            name: "serial",
            placeholder: "Any e.g. XYZ123",
            validator: (val, isDup) => !isDup && !isWildcard(val),
            isRequired: false,
        },
        modelField: {
            name: "model",
            placeholder: "Any e.g. F370",
            validator: (val) => !isWildcard(val),
            isRequired: false,
        },
        technologyField: {
            name: "technology",
            // "* e.g., pj, stratasys, pbf"
            placeholder: `${Object.keys(PrinterVendorType).join(", ")}`,
            validator: (val: string) =>
                val === null || val === "" || !!stringToPrinterVendorType(val),
            isRequired: false,
        },
    };

    renderMachineRow(index: number, machine: MachineEntitlementState): JSX.Element {
        let readOnlyFieldNames: (keyof MachineFieldMetas)[] = [];
        if (!this.isGlobalAdmin || this.props.isRenewing) {
            // for company-admin Licenses page (/product/#), almost everything is read-only [except for "name"]
            readOnlyFieldNames = ["serialField", "modelField"];
        }

        // Only global admins can delete machineentitlements
        const onDeleteButtonPressed =
            (this.isGlobalAdmin && !this.props.isRenewing && this.props.onDeleteButtonPressed) ||
            undefined;

        return (
            <MachineRow
                key={index}
                index={index}
                machine={machine}
                readOnlyFieldNames={readOnlyFieldNames}
                fieldMetas={this.fieldMetas}
                isGlobalAdmin={this.isGlobalAdmin}
                isPlaceholder={machine.isPlaceholder}
                isDuplicate={machine.isDuplicate}
                onChange={this.props.onChange}
                onDeleteButtonPressed={onDeleteButtonPressed}
                disabled={this.props.disabled}
            />
        );
    }

    renderMachineRows() {
        let machines: MachineEntitlementState[] = this.props.machines;
        if (machines.length === 0 && this.props.role === Role.GlobalAdmin) {
            // Always show at least 1 placeholder item [even when data model is empty].
            // Saves the user (global admin) the effort of clicking on "Add Machine".
            machines = [{ isPlaceholder: true }];
        }

        const rows: JSX.Element[] = [];
        for (let i = 0; i < machines.length; i++) {
            rows.push(
                this.renderMachineRow(i, {
                    serial: machines[i].serial,
                    model: machines[i].model,
                    technology: machines[i].technology,
                    isPlaceholder: machines[i].isPlaceholder,
                    isDuplicate: machines[i].isDuplicate,
                })
            );
        }
        return rows;
    }

    renderDeleteColumnHeader() {
        if (this.isGlobalAdmin) {
            return <th style={{ width: "35px", paddingLeft: "0" }} />;
        }
    }

    render() {
        const applicationContext = this.context as IApplicationContext;
        const { t } = applicationContext;
        // NB: Bottom margin needed to make sure there is gutter spacing between LicenseForm.notesField + MachineEntitlementList for material-type features
        return (
            <div style={{ margin: "20px 0", paddingTop: 0 }}>
                {this.props.toggleMatchAny && (
                    <Checkbox
                        toggle
                        label="Any Machine"
                        checked={this.props.isMatchAny}
                        className="ui right floated"
                        style={{ float: "right", padding: "0.5em" }}
                        onChange={!this.props.disabled ? this.props.toggleMatchAny : undefined} // Disable toggle
                        data-testid="qa-toggle-match-any"
                    />
                )}
                <h3>{this.props.title}</h3>
                <table className="ui table">
                    <thead>
                        <tr>
                            <DecoratedHeader meta={this.fieldMetas.serialField}>
                                {t("machines.serial_number")}
                            </DecoratedHeader>
                            {this.isGlobalAdmin && (
                                <DecoratedHeader meta={this.fieldMetas.technologyField}>
                                    {t("machines.machine_vendor")}
                                </DecoratedHeader>
                            )}
                            <DecoratedHeader meta={this.fieldMetas.modelField}>
                                {t("machines.machine_type")}
                            </DecoratedHeader>
                            {this.renderDeleteColumnHeader()}
                        </tr>
                    </thead>
                    {/* Don't render machine rows if license matches any/all machines */}
                    <tbody>{!this.props.isMatchAny && this.renderMachineRows()}</tbody>
                </table>
            </div>
        );
    }
}

interface MachineRowProps {
    index: number;
    machine: MachineEntitlementState;
    /** Which fields to make read-only. Otherwise, fields are editable. */
    readOnlyFieldNames: (keyof MachineFieldMetas)[];
    fieldMetas: MachineFieldMetas;
    isGlobalAdmin?: boolean;
    isPlaceholder?: boolean;
    isDuplicate?: boolean;
    disabled?: boolean;
    onChange: OnMachineEntitlementChange;
    onDeleteButtonPressed?: OnDeleteRequest;
}

const MachineRow: React.FunctionComponent<MachineRowProps> = ({
    index,
    machine,
    readOnlyFieldNames,
    fieldMetas,
    isGlobalAdmin,
    isPlaceholder,
    isDuplicate,
    onChange,
    onDeleteButtonPressed,
    disabled,
}) => {
    const [resetStateCounter, setResetStateCounter] = useState(0);

    const commonProps = {
        index,
        resetStateCounter,
        isPlaceholder,
        isDuplicate,
        onChange,
        readOnly: false,
    };

    return (
        <tr key={index}>
            <MachineField
                {...commonProps}
                readOnly={readOnlyFieldNames.includes("serialField")}
                value={machine.serial}
                meta={fieldMetas.serialField}
                disabled={disabled}
            />
            {isGlobalAdmin && (
                <MachineField
                    {...commonProps}
                    value={machine.technology}
                    meta={fieldMetas.technologyField}
                    disabled={disabled}
                />
            )}
            <MachineField
                {...commonProps}
                readOnly={readOnlyFieldNames.includes("modelField")}
                value={machine.model}
                meta={fieldMetas.modelField}
                disabled={disabled}
            />

            {index !== 0 && onDeleteButtonPressed && (
                <DeleteButton
                    id={`qa-machine-input-delete-${index}`}
                    onClick={
                        // Only global admin can delete machines
                        !onDeleteButtonPressed
                            ? undefined
                            : () => {
                                  // Cause the current MachineRow to reset its state (i.e., reset invalid indicator, etc).
                                  // The DOM element may be re-used for the next machine (after the one we're currently deleting).
                                  setResetStateCounter(resetStateCounter + 1);
                                  onDeleteButtonPressed(index);
                              }
                    }
                    data-testid={`qa-machine-delete-${index}`}
                />
            )}
        </tr>
    );
};

type MachineFieldProps = FormEditableFieldProps<MachineEntitlementState> & {
    readOnly?: boolean;
    isPlaceholder?: boolean;
    isDuplicate?: boolean;
    disabled?: boolean;
    onChange?: OnMachineEntitlementChange;
};

const MachineField: React.FunctionComponent<MachineFieldProps> = ({
    index,
    value = "",
    meta,
    resetStateCounter,
    readOnly,
    isPlaceholder,
    isDuplicate,
    onChange,
    disabled,
}) => {
    const uniqueTestId = `qa-machine-field-${meta.name}-${index}`;

    if (readOnly || !onChange) {
        return <NonEditableField value={value} />;
    }

    const fieldMeta = { ...meta };
    if (isPlaceholder) {
        fieldMeta.validator = undefined;
    }

    return (
        <EditableField
            index={index}
            resetStateCounter={resetStateCounter}
            value={value || ""}
            meta={fieldMeta}
            isDuplicate={isDuplicate}
            onChange={onChange}
            disabled={disabled}
            dataTestId={uniqueTestId}
        />
    );
};

const EditableField: React.FunctionComponent<FormEditableFieldProps<MachineEntitlementState>> = (
    props
) => (
    <FormEditableField<MachineEntitlementState>
        idPrefix="qa-machine-input-"
        {...props}
        dataTestId={props.dataTestId}
        disabled={props.disabled}
    />
);

interface NonEditableFieldProps {
    value: string | null;
}

const NonEditableField: React.FunctionComponent<NonEditableFieldProps> = ({ value }) => {
    const { t } = useContext(ApplicationContext);

    if (value === null) {
        return (
            <TableData>
                <FieldPlaceholderValue value={t("machines.any")} />
            </TableData>
        );
    }
    if (value) {
        return <TableData>{value}</TableData>;
    }

    return (
        <TableData>
            <FieldPlaceholderValue value={t("machines.unknown")} />
        </TableData>
    );
};

interface DeleteButtonProps {
    id?: string;
    onClick?: () => void;
}

const DeleteButton: React.FunctionComponent<DeleteButtonProps> = ({ id, onClick }) => {
    const disabled = !onClick;
    return (
        <td id={id} style={{ paddingLeft: "0" }}>
            <ImageContainer onClick={onClick}>
                <Image id={id} src={TrashIcon} disabled={disabled} />
            </ImageContainer>
        </td>
    );
};
