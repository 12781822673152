import { FunctionComponent, useContext } from "react";
import { IScreenProps } from "../Screen";
import { Query } from "@apollo/client/react/components";
import { QueryResult, useMutation } from "@apollo/client";
import { Notifier } from "../../view/Notifier";
import { UpdateStrategy } from "../../graphql";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { ApplicationContext } from "../../components/ApplicationProvider";
import {
    SoftwareBundle,
    SoftwareTag,
    UPDATE_COMPANY_SOFTWARE_VERSION,
    VERSION_MANAGEMENT_GET_FORM_DATA,
    VersionManagementGetFormDataResult,
} from "./VersionManagementQueries";
import { VersionManagementForm } from "./VersionManagementForm";
import { InfoBox } from "../../components/Styles";

/**
 * Version management screen
 */
export const VersionManagement: FunctionComponent<IScreenProps> = () => {
    const { t } = useContext(ApplicationContext);
    const breadCrumbs = <Breadcrumbs sections={[{ label: t("version_management.breadcrumb") }]} />;

    const [updateCompanySoftwareVersion] = useMutation(UPDATE_COMPANY_SOFTWARE_VERSION, {
        onError: (error) => Notifier.error(error),
        onCompleted: () => Notifier.success(t("version_management.update_success")),
    });

    return (
        <Query<VersionManagementGetFormDataResult> query={VERSION_MANAGEMENT_GET_FORM_DATA}>
            {({ loading, error, data }: QueryResult<VersionManagementGetFormDataResult>) => {
                if (error) {
                    Notifier.error(error);
                    return null;
                }
                if (loading || !data) {
                    return <div className="ui loader active" />;
                }

                const companySoftwareVersion = data.getCompanySoftwareVersion ?? {};
                const softwareBundles = data.getSoftwareBundles ?? [];
                const softwareTags = data.getSoftwareTags ?? [];
                const updateStrategy =
                    companySoftwareVersion.updateStrategy ?? UpdateStrategy.LatestRelease;
                const { bundle, tag } = companySoftwareVersion;

                /**
                 * Update the company software versions in the backend - this is called when we press Submit button
                 */
                const updateVersions = async (
                    newStrategy: UpdateStrategy,
                    newBundle?: SoftwareBundle,
                    newTag?: SoftwareTag
                ) => {
                    try {
                        await updateCompanySoftwareVersion({
                            variables: {
                                updateStrategy: newStrategy,
                                bundleVersion: newBundle?.id,
                                tag: newTag?.id,
                            },
                            refetchQueries: [{ query: VERSION_MANAGEMENT_GET_FORM_DATA }],
                        });
                    } catch (err) {
                        Notifier.error({ message: t("version_management.update_error") });
                    }
                };

                return (
                    <div style={{ paddingBottom: 25, maxWidth: 550 }}>
                        {breadCrumbs}
                        <h2 style={{ paddingBottom: "32px" }} className="page-header">
                            {t("version_management.breadcrumb")}
                        </h2>

                        <InfoBox>{t("version_management.deceription")}</InfoBox>

                        <VersionManagementForm
                            updateStrategy={updateStrategy}
                            bundle={bundle}
                            softwareBundles={softwareBundles}
                            softwareTags={softwareTags}
                            tag={tag}
                            t={t}
                            updateVersions={updateVersions}
                        />
                    </div>
                );
            }}
        </Query>
    );
};
