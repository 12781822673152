import { Component } from "react";
import { IErrorObject } from "../view/Notifier";
import { PageNotFound } from "./PageNotFound";
import { UNAUTHENTICATED } from "@grabcad/company-server-shared/dist/constants";

export interface IErrorBoundaryObject extends IErrorObject {
    errorKey: string;
    error?: Error;
}

const mapErrorToMessage = ({
    errorKey,
    networkError,
    graphQLErrors,
    error,
}: Partial<IErrorBoundaryObject>): string[] => {
    let errorMessages = new Set<string>();
    if (networkError) {
        errorMessages.add("Network error: failed to reach the server");
    }
    if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
            if (err.extensions) {
                if (err.extensions.code === UNAUTHENTICATED) {
                    errorMessages.add(
                        "Access denied: you must be authenticated to see this content."
                    );
                } else {
                    errorMessages.add(err.message);
                }
            }
        });
    }
    if (errorKey) {
        errorMessages.add(errorKey);
    }
    if (error?.message) {
        errorMessages.add(error.message);
    }
    return Array.from(errorMessages.values());
};

interface IErrorBoundaryProps {
    children?: any;
}

interface IErrorBoundaryState {
    hasError: boolean;
    message: string;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, message: "" };
    }

    componentDidCatch(error: IErrorObject, info: any) {
        // Display fallback UI
        let errorMessage = mapErrorToMessage(error).join("\n");
        this.setState({ hasError: true, message: errorMessage });
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children;
        }

        return <PageNotFound />;
    }
}

export { ErrorBoundary, mapErrorToMessage };
