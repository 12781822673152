import React, { useContext, useEffect, useState, FormEvent } from "react";
import { Button, Form, FormField, Input } from "grabcad-ui-elements";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { useMutation } from "@apollo/client";
import {
    CREATE_PRINTER_GROUP,
    UPDATE_PRINTER_GROUP,
    DELETE_PRINTER_GROUP,
} from "@grabcad/company-server-shared/dist/graphql/Mutations/PrinterGroups";
import _ from "lodash";
import { PrinterGroup } from "../../graphql";
import { PRINTER_GROUPS } from "@grabcad/company-server-shared/dist/graphql/Queries/PrinterGroups";
import { deepEquals } from "../../utils/form";
import { Notifier } from "../../view/Notifier";
import { useHistory } from "react-router";
import { PATHS } from "../../Routes";
import { USER_GROUP_PRINTER_GROUP_ACCESSES } from "@grabcad/company-server-shared/dist/graphql/Queries/UserGroupPrinterGroupAccesses";
import { DeleteEntityConfirmation } from "../../view/Modals/DeleteEntityConfirmation";
import {
    StyledModal,
    StyledModalHeader,
    StyledCloseButton,
    StyledModalContent,
    StyledModalActions,
    StyledNumberSelected,
    StyledActions,
} from "./StyledModal";
import classnames from "classnames";
import { StyledDeleteButton } from "../../components/Styles";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";
import { NoPrinter } from "./NoPrinter";
import { useGetAllPrinters } from "./UseGetAllPrinters";
import { Printers } from "./Printers";

export enum PrinterGroupModalType {
    Create = "CREATE",
    Edit = "EDIT",
}

export const GROUP_NAME_MAX_LENGTH = 100;

interface Props {
    /** Modal type. */
    type: PrinterGroupModalType;
    /** Open/close state of modal. */
    open: boolean;
    /** Existing printer group data. Only applicable for edit modal type. */
    group?: PrinterGroup;
    /** Modal close event handler. */
    onClose: () => void;
}

function usePrinterGroupsQuery(): PrinterGroup[] {
    const { data } = useQueryWithCatch<{ printerGroups: PrinterGroup[] }>(PRINTER_GROUPS);

    return data?.printerGroups ? data.printerGroups : [];
}

/**
 * Modal for creating or updating a printer group.
 */
const PrinterGroupModal: React.FC<Props> = ({
    type = PrinterGroupModalType.Create,
    open,
    group,
    onClose,
}: Props) => {
    const { t } = useContext(ApplicationContext);
    const history = useHistory();

    const { loadingPrinters, printers, errorOccurred, errorMessage } = useGetAllPrinters();
    const somePrintersMissing = group?.printerIds?.some(
        (id) => !printers.some((p) => p.getId() === id)
    );

    const printerGroupModalType = type.toLocaleLowerCase();

    const [printerGroupName, setPrinterGroupName] = useState<string>(group?.name || "");
    const [printerGroupIds, setPrinterGroupIds] = useState<string[]>(group?.printerIds || []);
    const [printerGroupNameFieldTouched, setPrinterGroupNameFieldTouched] =
        useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [deleteClicked, setDeleteClicked] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<{ [x: string]: string }>({});

    const [nameInvalidReason, setNameInvalidReason] = useState<string | undefined>();

    const allGroups = usePrinterGroupsQuery();

    const getNameInvalidReason = (newName: string): string => {
        const trimmedName = newName.trim();

        if (!trimmedName.length) {
            return t("printer_modal.validation.printer_group_name_required");
        }
        if (trimmedName.length > GROUP_NAME_MAX_LENGTH) {
            return t("printer_modal.validation.printer_group_name_too_long", {
                maxLength: GROUP_NAME_MAX_LENGTH,
            });
        }
        if (
            allGroups.find(
                (printerGroup) => printerGroup.name === trimmedName && printerGroup.id !== group?.id
            )
        ) {
            return t("printer_modal.validation.printer_group_name_taken");
        }
        return "";
    };

    const [createPrinterGroup] = useMutation(CREATE_PRINTER_GROUP);
    const [updatePrinterGroup] = useMutation(UPDATE_PRINTER_GROUP);

    const isDirty =
        !deepEquals(group?.name, printerGroupName) ||
        !deepEquals(type === PrinterGroupModalType.Edit ? group?.printerIds : [], printerGroupIds);

    /**
     * Conditionally add or remove printer from group by printer ID.
     *
     * @param {string} printerId ID of printer to add or remove
     */
    const updatePrinterGroupIds = (printerId: string) => {
        const updatedIds = printerGroupIds.includes(printerId)
            ? // remove printer ID from group if present
              printerGroupIds.filter((id) => id !== printerId)
            : // add printer ID to group if not present
              _.union(printerGroupIds, [printerId]);

        setPrinterGroupIds(updatedIds);
    };

    // update form errors
    useEffect(() => {
        // validate printer group name field

        if (printerGroupNameFieldTouched && !printerGroupName) {
            setFormErrors({
                message: t("forms.field_required"),
            });
        } else if (nameInvalidReason) {
            setFormErrors({
                message: nameInvalidReason,
            });
        } else {
            setFormErrors({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printerGroupName, nameInvalidReason]);

    /**
     * Handle form cancel (modal closed without form submission) event.
     */
    const onCancel = () => {
        // clear form state
        clearForm();

        // trigger passed-in close handler callback
        onClose();
    };

    const clearForm = () => {
        setSubmitClicked(false);
        setPrinterGroupName(group?.name || "");
        setPrinterGroupIds(group?.printerIds || []);
        setNameInvalidReason("");
        setFormErrors({});
    };

    /**
     * Form submit handler.
     */
    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setSubmitClicked(true);

        const reason = getNameInvalidReason(printerGroupName);
        setNameInvalidReason(reason);

        if (!reason) {
            switch (type) {
                // create new printer group
                case PrinterGroupModalType.Create:
                    void createPrinterGroup({
                        variables: { name: printerGroupName, printerIds: printerGroupIds },
                        update: (cache, { data }) => {
                            // extract current printer groups from Apollo cache
                            const { printerGroups } =
                                cache.readQuery<{
                                    printerGroups: PrinterGroup[];
                                }>({
                                    query: PRINTER_GROUPS,
                                }) || {};

                            // if cache hit, add new printer group to cache
                            if (printerGroups) {
                                const newPrinterGroup = data.createPrinterGroup;

                                cache.writeQuery({
                                    query: PRINTER_GROUPS,
                                    data: {
                                        printerGroups: [...printerGroups, newPrinterGroup],
                                    },
                                });
                            }

                            Notifier.success(t("printer_groups.successfully_created"));

                            onClose();

                            // navigate to new printer group page
                            history.push(`${PATHS.printerGroup}/${data.createPrinterGroup.id}`);
                        },
                        onError: (error) => Notifier.error(error),
                    });

                    break;
                // update existing printer group
                case PrinterGroupModalType.Edit:
                    if (group) {
                        const printerIdsToAdd = printerGroupIds.filter(
                            (id) => !group.printerIds.includes(id)
                        );
                        const printerIdsToRemove = group.printerIds.filter(
                            (id) => !printerGroupIds.includes(id)
                        );

                        void updatePrinterGroup({
                            variables: {
                                id: group.id,
                                name: printerGroupName,
                                printerIdsToAdd,
                                printerIdsToRemove,
                            },
                            onError: (error) => Notifier.error(error),
                            onCompleted: () =>
                                Notifier.success(t("printer_groups.successfully_updated")),
                        });
                    }

                    break;
                default:
                    break;
            }

            setSubmitClicked(false);
            setPrinterGroupNameFieldTouched(false);
            onClose();
        } else {
            setSubmitClicked(false);
            setPrinterGroupNameFieldTouched(false);
        }
    };

    return (
        <StyledModal basic open={open} onClose={onCancel}>
            <StyledModalHeader>
                {type === PrinterGroupModalType.Edit ? (
                    <h3>{t("printer_groups.edit_printer_group")}</h3>
                ) : (
                    <h3>{t("printer_groups.new_printer_group")}</h3>
                )}
                <StyledCloseButton
                    className={`qa-printerGroups-${printerGroupModalType}PrinterGroupModal-cancel`}
                    name="remove"
                    onClick={onCancel}
                />
            </StyledModalHeader>

            <Form onSubmit={onSubmit}>
                <StyledModalContent>
                    <FormField key="name" className="required">
                        <label>{t("printer_groups.group_name")}</label>
                        <Input
                            className={classnames(
                                `qa-printerGroups-${printerGroupModalType}PrinterGroupModal-name-input`,
                                "qa-printerGroupName",
                                {
                                    error: !!formErrors.message,
                                }
                            )}
                            value={printerGroupName}
                            onChange={(event) => {
                                if (nameInvalidReason) {
                                    setNameInvalidReason("");
                                }
                                setPrinterGroupNameFieldTouched(true);
                                setPrinterGroupName(event.currentTarget.value);
                            }}
                        />
                    </FormField>
                    {formErrors.message && (
                        <p className="ui negative message qa-formValidationMessage">
                            {formErrors.message}
                        </p>
                    )}

                    {loadingPrinters ? (
                        <div className="ui loader active" />
                    ) : !printers.length || errorOccurred ? (
                        <NoPrinter
                            errorOccurred={errorOccurred}
                            errorMessage={errorMessage}
                            isPopUp={true}
                        />
                    ) : (
                        <Printers
                            printers={printers}
                            onClick={updatePrinterGroupIds}
                            selectedPrinterIds={printerGroupIds}
                            setSelectedPrinterIds={setPrinterGroupIds}
                        />
                    )}
                </StyledModalContent>

                <StyledModalActions>
                    <StyledNumberSelected noneSelected={!printerGroupIds.length}>
                        {printerGroupIds.length
                            ? printerGroupIds.length === 1
                                ? `${printerGroupIds.length} ${t("printer_groups.one_selected")}`
                                : `${printerGroupIds.length} ${t(
                                      "printer_groups.multiple_selected"
                                  )}`
                            : t("printer_groups.none_selected")}
                        {somePrintersMissing ? ` ${t("printer_groups.some_printers_missing")}` : ""}
                    </StyledNumberSelected>

                    <StyledActions>
                        {type === PrinterGroupModalType.Edit && (
                            <StyledDeleteButton
                                className="qa-printerGroups-delete ui button"
                                negative
                                type="button"
                                onClick={() => {
                                    setDeleteClicked(true);
                                }}
                            >
                                {t("printer_groups.delete_printer_group")}
                            </StyledDeleteButton>
                        )}

                        <FormField key="submit">
                            <Button
                                className={classnames(
                                    `qa-printerGroups-${printerGroupModalType}PrinterGroupModal-${printerGroupModalType}`,
                                    "qa-printerGroups-submit"
                                )}
                                type="submit"
                                disabled={
                                    !isDirty ||
                                    submitClicked ||
                                    (PrinterGroupModalType.Create && !printerGroupName)
                                }
                            >
                                {t("forms.save")}
                            </Button>
                        </FormField>
                    </StyledActions>
                </StyledModalActions>
            </Form>
            {deleteClicked && (
                <DeleteEntityConfirmation
                    open
                    entity={{ id: group?.id, name: printerGroupName }}
                    type={t("printer_groups.printer_group")}
                    mutation={DELETE_PRINTER_GROUP}
                    refetchQueries={[
                        { query: PRINTER_GROUPS },
                        { query: USER_GROUP_PRINTER_GROUP_ACCESSES },
                    ]}
                    onClose={() => setDeleteClicked(false)}
                    update={() => {
                        Notifier.success(t("printer_groups.successfully_deleted"));
                        history.push(`${PATHS.printerGroups}`);
                    }}
                />
            )}
        </StyledModal>
    );
};

export default PrinterGroupModal;
