import styled, { Button, Form } from "grabcad-ui-elements";

// Style for PrinterSerial component
export const StyledGatewaysListUI = styled.div`
    margin: 10px;
    padding: 0px;
    & > div {
        &.centered {
            text-align: center;
        }

        &.flex {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 10px;

            label {
                h3 {
                    margin-top: 5px;
                }
            }
        }
    }
`;

export const spinnerUI = <div className="ui active centered inline loader" />;

export const StyledForm = styled(Form)`
    max-width: 550px;
    padding-bottom: 25px;
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    gap: 12px;

    label {
        color: #4183c4;
    }
`;

export const StyledButton = styled(Button)`
    background-color: #700ac1 !important;
    border-color: #700ac1 !important;
    color: white !important;
    &:hover {
        background-color: #700ac1 !important;
    }
    &:focus {
        background-color: #700ac1 !important;
    }
    .MuiCircularProgress-root {
        color: #700ac1 !important;
    }
    margin-left: 10px;
`;

export const FormContainer = styled.div`
    margin: 0 0 1em 0;
`;

// Styles for GatewayPrinterCard component
export const StyledCard = styled.div`
    width: 20%;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 10px;
    border: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;

    h4 {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .labelContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.4rem 0.3rem;
    }
`;
