import { useState } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment, { Moment } from "moment-timezone";
import styled, { Container } from "grabcad-ui-elements";

export interface IDatePickerProps {
    value: string | undefined;
    onDateChange: (date: Moment | null) => void;
    openDirection?: "up" | "down";
    anchorDirection?: "left" | "right";
    id?: string;
    disabled?: boolean;
}

const CalendarStyle = styled(Container)`
    .SingleDatePicker,
    .SingleDatePickerInput {
        width: 100%;
        position: relative;
        .SingleDatePickerInput_calendarIcon {
            position: absolute;
            z-index: 1;
            padding: 6px 10px 8px 10px;
            margin: 0;
        }
        .DateInput {
            width: 100%;
            input[type="text"].DateInput_input {
                padding: 0 1em 0 33px;
                height: 32px;
                border: 1px solid #ccc !important;
                max-width: 100%;
                // needs to be set here because grabcad-ui-elements do not override the default one
                &:focus {
                    border-color: #003393 !important;
                }
            }
        }
    }
    .CalendarMonth_table {
        margin-top: 10px;
    }
    .CalendarMonth .CalendarMonth_table .CalendarDay {
        border: 1px solid #e4e7e7;
        border-top: 1px solid #e4e7e7;
        padding: 0px;
    }
    .CalendarDay__selected_span.CalendarDay__selected_span {
        background: #2185d0;
        color: white;
        border: 1px solid #2185d0;
    }
    .CalendarDay__selected.CalendarDay__selected {
        background: #2185d0;
        color: white;
        border: 1px solid #2185d0;
        &:hover {
            background: #2185d0;
            color: white;
        }
    }
    .CalendarDay__hovered_span.CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span.CalendarDay__hovered_span {
        background: #2185d0;
    }
`;

export const FormattedDatePicker = ({
    value,
    onDateChange,
    openDirection = "down",
    anchorDirection = "left",
    id = "qa-datePicker",
    disabled = false,
}: IDatePickerProps) => {
    const [dateFocused, setDateFocused] = useState(false);

    return (
        <CalendarStyle fluid>
            <SingleDatePicker
                id={id}
                date={(value && moment(value)) || null}
                focused={dateFocused}
                onDateChange={onDateChange}
                onFocusChange={({ focused }: { focused: boolean | null }) =>
                    setDateFocused(focused || false)
                }
                placeholder=""
                showDefaultInputIcon
                numberOfMonths={1}
                openDirection={openDirection}
                anchorDirection={anchorDirection}
                hideKeyboardShortcutsPanel
                noBorder
                disabled={disabled}
                isOutsideRange={() => false}
                verticalSpacing={0}
            />
        </CalendarStyle>
    );
};
