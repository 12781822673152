import { Button } from "grabcad-ui-elements";
import { useContext, useEffect, useState, ChangeEvent, FormEvent } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { Notifier } from "../../view/Notifier";
import GatewayModal from "./GatewayModal";
import GatewaysList, { RemovableEntityType } from "./GatewaysList";
import { Container } from "semantic-ui-react";
import { IGateway, MaybeAssociatedClientDevice } from "@grabcad/company-server-shared/dist/types";
import { getGatewayList, getOrphanedClients } from "../../zeus-client/gatewaysApis";
import { useCompanyAsAdminQuery } from "../../graphql";
import { useCompanyLicensesQuery } from "../../graphql/Queries/Licenses/GetCompanyLicenses";
import { useGetCompaniesByExternalId } from "../../graphql/Queries/Companies/CompanyListWithExternalId";
import { findExternalCompanyIdKey } from "./renderOwnedByLabels";
import GatewayPrinterCard from "./GatewayPrinterCard";
import {
    CompanyTitle,
    GatewayText,
    NoGatewaysMessage,
    PrinterSerialLabel,
} from "./PrinterGatewaysHelper";
import {
    FormContainer,
    spinnerUI,
    StyledButton,
    StyledForm,
    StyledGatewaysListUI,
} from "./PrinterGatewayStyles";

enum PromiseStatus {
    Fulfilled = "fulfilled",
    Rejected = "rejected",
}

export const PrinterSerial = () => {
    const { t } = useContext(ApplicationContext);
    const { company } = useCompanyAsAdminQuery();
    const { allEnabledFeatureKeys } = useCompanyLicensesQuery();

    const [newThingName, setcoreDeviceThingName] = useState<string>("");
    const [isShowSpinner, setSpinnerState] = useState<boolean>(false); // Loading gateway list by button

    const [createGatewayModalOpen, setCreateGatewayModalOpen] = useState<boolean>(false); // Check if the create gateway modal is open
    const [displayAddGatewayButton, setDisplayAddGatewayButton] = useState<boolean>(false); // Show add button or not
    const [checkIfPollingTrue, setCheckIfPollingTrue] = useState<boolean>(false); // Close modal and recall API gateway status

    const [companiesListItem, setCompaniesListItem] = useState<{ id: number; name: string }[]>([]);
    const [externalId, setExternalId] = useState<string>("");
    const { data: companiesList } = useGetCompaniesByExternalId(externalId);

    const [userType, setUserType] = useState<{ isCSE: boolean; isReseller: boolean }>({
        isCSE: false,
        isReseller: false,
    });

    const [searchPrinterSerialInput, setSearchPrinterSerialInput] = useState<string>("");
    const [isDeviceFound, setIsDeviceFound] = useState<boolean>(false); //Flag decided to show search result or not
    const [printerState, setPrinterState] = useState<{
        printerSerial: string;
        isPrinterSerialFound: boolean;
        printerGateway: string;
    }>({
        printerSerial: "",
        isPrinterSerialFound: false,
        printerGateway: "none",
    });

    const [gatewayAndClients, setGatewayAndClients] = useState<{
        gatewaysList: IGateway[];
        orphanedClients: MaybeAssociatedClientDevice[];
    }>({
        gatewaysList: [],
        orphanedClients: [],
    });

    const fetchGatewayAndClients = async (printerSerialToSearch?: string) => {
        setSpinnerState(true);

        try {
            const fetchData = async (serial?: string) =>
                await Promise.allSettled([getGatewayList(serial), getOrphanedClients(serial)]);

            let [gatewayResult, clientsResult] = await fetchData(printerSerialToSearch);
            if (
                printerSerialToSearch &&
                gatewayResult.status === PromiseStatus.Rejected &&
                clientsResult.status === PromiseStatus.Rejected
            ) {
                [gatewayResult, clientsResult] = await fetchData();
                handlePrinterSerialNotFound();
            }

            const gatewaysList =
                gatewayResult.status === PromiseStatus.Fulfilled ? gatewayResult.value : [];
            const orphanedClients =
                clientsResult.status === PromiseStatus.Fulfilled
                    ? filteredOrphanedClients(clientsResult.value)
                    : [];

            if (
                gatewayResult.status === PromiseStatus.Fulfilled ||
                clientsResult.status === PromiseStatus.Fulfilled
            ) {
                setGatewayAndClients({ gatewaysList, orphanedClients });
            }

            if (!printerSerialToSearch?.trim() && clientsResult.status === PromiseStatus.Rejected) {
                handlePrinterSerialNotFound();
            }

            let foundPrinter: MaybeAssociatedClientDevice | undefined;
            let printerGateway = "none";
            if (printerSerialToSearch?.trim() && clientsResult.status === PromiseStatus.Fulfilled) {
                foundPrinter = clientsResult.value.find(
                    (e) => e.thingName === printerSerialToSearch.trim()
                );
                setIsDeviceFound(!!foundPrinter);

                if (foundPrinter && gatewaysList.length > 0) {
                    const matchingGateway = gatewaysList.find(
                        (x) => x.coreDeviceThingName === foundPrinter?.coreDeviceThingName
                    );
                    const accountNumber = findExternalCompanyIdKey(
                        printerSerialToSearch,
                        clientsResult.value,
                        gatewaysList
                    );
                    if (accountNumber) {
                        setExternalId(accountNumber);
                    }

                    printerGateway = matchingGateway?.site || "none";
                }
            } else {
                setIsDeviceFound(false);
            }

            setPrinterState({
                printerSerial: foundPrinter ? printerSerialToSearch?.trim() || "" : "",
                isPrinterSerialFound: !!foundPrinter ? true : false,
                printerGateway,
            });
        } catch (error) {
            handleFetchError();
        } finally {
            setSpinnerState(false);
        }
    };

    const filteredOrphanedClients = (orphanedClients: MaybeAssociatedClientDevice[]) =>
        orphanedClients?.filter((x) => !x.hasOwnProperty("coreDeviceThingName")) || [];

    const shouldEnableConnectPrinter = () => Boolean(searchPrinterSerialInput?.length);

    useEffect(() => {
        setCompanyTypeAndRole();
        void fetchGatewayAndClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (checkIfPollingTrue) {
            void fetchGatewayAndClients(printerState?.printerSerial);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkIfPollingTrue]);

    useEffect(() => {
        if (companiesList?.getCompaniesByExternalId?.companies) {
            setCompaniesListItem(companiesList.getCompaniesByExternalId.companies);
        }
    }, [companiesList]);

    const setCompanyTypeAndRole = () => {
        setUserType({
            isCSE: allEnabledFeatureKeys.has("zeus_stratasys_cse"),
            isReseller: company?.companyType === "reseller",
        });
    };

    const onPrinteridchange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setSearchPrinterSerialInput(
            event.currentTarget.value ? event.currentTarget.value.trim() : ""
        );
    };

    const handlePrinterSerialNotFound = () => {
        Notifier.fail(t("gateways.printer_serial_not_found"));
        setPrinterState({
            printerSerial: "",
            isPrinterSerialFound: false,
            printerGateway: "none",
        });
        setIsDeviceFound(false);
        setSearchPrinterSerialInput("");
        setDisplayAddGatewayButton(false);
    };

    const handleFetchError = () => {
        Notifier.fail(t("gateways.gateway_request_error"));
        setPrinterState({
            printerSerial: "",
            isPrinterSerialFound: false,
            printerGateway: "none",
        });

        setGatewayAndClients({
            gatewaysList: [],
            orphanedClients: [],
        });
        setIsDeviceFound(false);
        setSearchPrinterSerialInput("");
        setDisplayAddGatewayButton(false);
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            //clean the api list as per api call
            setExternalId("");
            //call api for search printer serial
            void fetchGatewayAndClients(searchPrinterSerialInput);
        } catch (error) {
            await handleFetchError();
        }
    };

    function openGatewayModal() {
        setcoreDeviceThingName("");
        setCreateGatewayModalOpen(!createGatewayModalOpen);
        setCheckIfPollingTrue(false);
    }

    const updateGatewaysAndOrphans = (thingName: string, thingType: string) => {
        if (!thingName) {
            return;
        }
        setcoreDeviceThingName("");
        let { gatewaysList, orphanedClients } = gatewayAndClients;
        // eslint-disable-next-line default-case
        switch (thingType) {
            case RemovableEntityType.Analyze:
                gatewaysList.forEach((gateway) => {
                    gateway.associatedClientDevices = gateway.associatedClientDevices?.filter(
                        (device) => device.thingName !== thingName
                    );
                });
                break;

            case RemovableEntityType.Printer: {
                let printerType: string | undefined;
                gatewaysList.forEach((gateway) => {
                    gateway.associatedClientDevices = gateway.associatedClientDevices?.filter(
                        (device) => {
                            if (device.thingName === thingName) {
                                printerType = device.printerType;
                            }
                            return device.thingName !== thingName;
                        }
                    );
                });

                if (
                    printerType &&
                    !orphanedClients.some((client) => client.thingName === thingName)
                ) {
                    orphanedClients.push({ thingName, printerType });
                }
                break;
            }

            case RemovableEntityType.Gateway:
                gatewaysList = gatewaysList.filter(
                    (gateway) => gateway?.coreDeviceThingName !== thingName
                );
                break;
        }

        setGatewayAndClients({
            gatewaysList: [...gatewaysList],
            orphanedClients: [...orphanedClients],
        });
    };

    const gatewayModal = (
        <>
            <GatewayModal
                open={createGatewayModalOpen}
                onClose={() => {
                    setCreateGatewayModalOpen(false);
                }}
                onSuccessfullRequest={() => {
                    setCreateGatewayModalOpen(false);
                    Notifier.success(t("gateways.job_status_fetching_message"));
                }}
                setCheckIfPollingTrue={(property: boolean) => setCheckIfPollingTrue(property)}
                setNewThingName={(thingName: string) => setcoreDeviceThingName(thingName)}
                // By passing printerSerial, it facilitates Gateway association between CSE/Resellers and customers using the PrinterSerial.
                // which in turn helps in association of a gateway to a company to which the printer serial number belongs to
                printerSerial={printerState?.printerSerial}
                data-testid="gateway-modal"
            ></GatewayModal>
        </>
    );

    const AddGatewayButton = () => (
        <Button
            type="button"
            onClick={() => openGatewayModal()}
            className="right floated"
            style={{ backgroundColor: "#700ac1", marginTop: "10px" }}
            data-testid="add-gateway-button"
        >
            {t("gateway_modal.add_gateway")}
        </Button>
    );

    // Constants for Conditions
    const hasNoGateways = gatewayAndClients?.gatewaysList?.length === 0;
    const hasNoOrphanedClients = gatewayAndClients?.orphanedClients?.length === 0;
    const isEmptyGatewayList = hasNoGateways && hasNoOrphanedClients;

    const shouldShowAddGatewayButton = isDeviceFound && isEmptyGatewayList;
    const shouldShowNoGatewaysMessage = !isDeviceFound && isEmptyGatewayList;

    const GatewaysContainer = () => (
        <div
            style={{ display: "flex", flexDirection: "column", gap: 10 }}
            data-testid="gateways-list-container"
        >
            {/* Display company title */}
            <CompanyTitle
                companyName={companiesList?.getCompaniesByExternalId?.companies?.[0]?.name ?? ""}
            />

            {/* List of gateways and orphaned clients */}
            <GatewaysList
                list={gatewayAndClients?.gatewaysList}
                newThingName={newThingName}
                orphanedClients={gatewayAndClients?.orphanedClients}
                printerSerial={printerState?.printerSerial}
                isCSE={userType?.isCSE}
                isReseller={userType?.isReseller}
                removeThingByName={updateGatewaysAndOrphans}
                data-testid="gateways-list"
            />

            <GatewayText />
        </div>
    );

    // Display Add Gateway Button UI
    const AddGatewayButtonUI = displayAddGatewayButton ? (
        <Container
            style={{ padding: "10px" }}
            display="!shouldEnableConnectPrinter()"
            data-testid="gateway-container"
        >
            <PrinterSerialLabel printerSerial={printerState?.printerSerial} />
            <GatewayPrinterCard companiesListItem={companiesListItem} printerState={printerState} />
            <AddGatewayButton />
            {gatewayModal}
        </Container>
    ) : null;

    // Main Gateways Display UI
    const GatewaysDisplay = (
        <StyledGatewaysListUI data-testid="gateways-list-ui">
            {/* Show Printer Serial Label and Gateway Printer Card if device is found */}
            {isDeviceFound && (
                <>
                    <PrinterSerialLabel printerSerial={printerState?.printerSerial} />
                    <GatewayPrinterCard
                        companiesListItem={companiesListItem}
                        printerState={printerState}
                    />
                </>
            )}

            {/* Show No Gateways Message if required */}
            {shouldShowNoGatewaysMessage ? <NoGatewaysMessage /> : <GatewaysContainer />}

            {/* Add Gateway Button */}
            <AddGatewayButton />
            {gatewayModal}
        </StyledGatewaysListUI>
    );

    // Determine what to show (Spinner or Gateways UI)
    const DisplayContent = isShowSpinner
        ? spinnerUI
        : shouldShowAddGatewayButton
        ? AddGatewayButtonUI
        : GatewaysDisplay;

    return (
        <FormContainer data-testid="form-container">
            {/* Conditional message for CSE users */}
            {allEnabledFeatureKeys.has("zeus_stratasys_cse")
                ? t("gateways.cse_add_gateway_message")
                : !isDeviceFound
                ? DisplayContent
                : null}

            <br />

            {/* Form for finding gateways */}
            <StyledForm onSubmit={onSubmit} data-testid="styled-form">
                <FormContainer data-testid="printer-serial-input-container">
                    <label htmlFor="input-printerId" data-testid="printer-serial-label">
                        {t("gateways.printer_serial_number")}
                    </label>
                    <input
                        data-testid="find-gateways-searchbox"
                        id="input-printerId"
                        value={searchPrinterSerialInput}
                        onChange={onPrinteridchange}
                        className="qa-company-input"
                    />
                </FormContainer>
                <FormContainer data-testid="connect-printer-button-container">
                    <StyledButton
                        data-testid="find-gateways-button"
                        id="connect-printer-button"
                        className="right floated buttonBgColor"
                        type="submit"
                        loading={isShowSpinner}
                        disabled={!shouldEnableConnectPrinter()}
                    >
                        {t("gateways.find_gateways")}
                    </StyledButton>
                </FormContainer>
            </StyledForm>

            {/* Show content based on conditions */}
            {isDeviceFound ? DisplayContent : null}
        </FormContainer>
    );
};
