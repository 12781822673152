import renderOwnedByLabels from "./renderOwnedByLabels";
import { useContext, useState } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { companyItem } from "../../graphql/Queries/Companies/CompanyListWithExternalId";
import { StyledCard } from "./PrinterGatewayStyles";

interface PrinterCardProps {
    companiesListItem: companyItem[];
    printerState: {
        printerSerial: string;
        printerGateway: string;
    };
}

const GatewayPrinterCard: React.FC<PrinterCardProps> = ({ companiesListItem, printerState }) => {
    // Moved state inside PrinterCard
    const [showAllCompaniesList, setShowAllCompaniesList] = useState(false);

    // Toggle function is now inside the component
    const toggleShowAll = (): void => {
        setShowAllCompaniesList((prev) => !prev);
    };
    const { t } = useContext(ApplicationContext);
    return (
        <StyledCard data-testid="printer-card">
            <h4 data-testid="printer_serial_number_label">{printerState?.printerSerial}</h4>
            <div className="labelContainer" data-testid="gateway-label-container">
                {renderOwnedByLabels({
                    companiesListItem,
                    showAllCompaniesList,
                    toggleShowAll,
                    t,
                })}
                <label data-testid="printer_associated_gatway_label">
                    {t("gateways.gateways_site_header")}: {printerState?.printerGateway}
                </label>
            </div>
        </StyledCard>
    );
};

export default GatewayPrinterCard;
