import { LicenseData, Role } from "../../graphql";
import { useContext, useState } from "react";
import { IScreenProps } from "../../screens/Screen";
import { ApplicationContext } from "../ApplicationProvider";
import { applicationLocale } from "../../i18n";
import gql from "graphql-tag";
import { DeleteEntityConfirmation } from "../../view/Modals/DeleteEntityConfirmation";
import { Notifier } from "../../view/Notifier";
import {
    TableHeaderNoWrap,
    TableDataNoWrap,
    LIGHT_BLUE,
    LIGHT_GREEN,
    LIGHT_YELLOW,
    LIGHT_RED,
    TRANSPARENT,
} from "../Styles";
import moment from "moment-timezone";
import { Button } from "grabcad-ui-elements";
import { GET_COMPANY_LICENSES } from "../../graphql/Queries/Licenses/GetCompanyLicenses";

export const DELETE_LICENSE = gql`
    mutation deleteLicense($id: Int!) {
        deleteLicense(id: $id)
    }
`;

export interface CompanyLicenseTableProps extends IScreenProps {
    role?: string;
    companyId: number;
    licenses?: LicenseData[]; // This can a Company's licenses, or a Product's
}

export const CompanyLicenseTable = ({
    role,
    licenses,
    companyId,
    history,
}: CompanyLicenseTableProps) => {
    const { t } = useContext(ApplicationContext);
    const locale = applicationLocale();
    const [licenseToDelete, setLicenseToDelete] = useState<LicenseData>();

    if (!licenses?.length) {
        return null;
    }

    const renderActionColumnHeader = () => {
        if (role === Role.GlobalAdmin) {
            return <TableHeaderNoWrap>{t("license_table.action")}</TableHeaderNoWrap>;
        }
        return null;
    };

    const renderActionColumnData = (license: LicenseData) => {
        const appType = license.package?.product?.applicationType?.id ?? "";
        // next line needs a better rule set to check for renewable licenses
        const canRenew =
            license.state === "expired" && appType.toLowerCase().indexOf("shop") === -1;
        const extendLink = (
            <>
                {" | "}
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/license/${license.id}`)}
                    data-testid="extend-btn"
                >
                    {t("license_table.extend")}
                </a>
            </>
        );
        if (role === Role.GlobalAdmin) {
            return (
                <TableDataNoWrap data-label="Actions">
                    <a
                        onClick={() => history.push(`/license/${license.id}?mode=view`)}
                        data-testid="view-btn"
                    >
                        {t("license_package_table.view")}
                    </a>
                    {" | "}
                    <a
                        style={{ cursor: "pointer" }}
                        onClick={() => setLicenseToDelete(license)}
                        data-testid="delete-btn"
                    >
                        {t("license_table.delete")}
                    </a>
                    {license.state !== "expired" && license.endDate ? extendLink : ""}
                    {canRenew && (
                        <>
                            {" | "}
                            <a
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    history.push(
                                        `/license/assign/${companyId}?renewFrom=${license.id}`
                                    )
                                }
                            >
                                {`${t("license_table.renew")}`}
                            </a>
                        </>
                    )}
                </TableDataNoWrap>
            );
        }
        return null;
    };

    const renderDeleteLicenseConfirmation = () => {
        if (role === Role.GlobalAdmin) {
            return (
                <DeleteEntityConfirmation
                    entity={{
                        id: licenseToDelete?.id,
                        name: licenseToDelete?.package.name,
                    }}
                    type="license"
                    mutation={DELETE_LICENSE}
                    refetchQueries={[{ query: GET_COMPANY_LICENSES, variables: { companyId } }]}
                    update={() => {
                        history.push(history.location);
                        Notifier.success("Successfully deleted license");
                    }}
                    open={!!licenseToDelete}
                    onClose={() => setLicenseToDelete(undefined)}
                />
            );
        }
        return null;
    };

    /**
     * Returns a background color for a given license status. Possible license statuses can be
     * found in "frontend\src\i18n\locales\en.json", in "license.states".
     *
     * @param {string} state license state name
     * @return {string} CSS color string
     */
    const getLicenseStateColor = (state: string) => {
        switch (state) {
            case "active": {
                return LIGHT_GREEN;
            }
            case "expiring": {
                return LIGHT_YELLOW;
            }
            case "expired": {
                return LIGHT_RED;
            }
            case "not_started": {
                return LIGHT_BLUE;
            }
            default: {
                return TRANSPARENT;
            }
        }
    };

    let totalSeats = 0;
    let totalSeatsUsed = 0;
    let anyUnlimitedLicense = false;

    // TODO - display expired licenses separately - https://grabcad.atlassian.net/browse/GC-60886
    licenses.forEach((license) => {
        if (!moment().isAfter(license.endDate)) {
            if (license.package.unlimitedMaxUsers) {
                anyUnlimitedLicense = true;
            }
            totalSeats += license.maxUsers;
            totalSeatsUsed += license.seatsUsed;
        }
    });

    return (
        <table className="ui table">
            <thead>
                <tr>
                    <TableHeaderNoWrap>{t("license_table.license_package_name")}</TableHeaderNoWrap>
                    <TableHeaderNoWrap>{t("license_table.order_id")}</TableHeaderNoWrap>
                    <TableHeaderNoWrap>{t("license_table.start_date")}</TableHeaderNoWrap>
                    <TableHeaderNoWrap>{t("license_table.end_date")}</TableHeaderNoWrap>
                    <TableHeaderNoWrap>{t("license_table.total")}</TableHeaderNoWrap>
                    <TableHeaderNoWrap>{t("license_table.used")}</TableHeaderNoWrap>
                    <TableHeaderNoWrap style={{ textAlign: "center" }}>
                        {t("license_table.status")}
                    </TableHeaderNoWrap>
                    {renderActionColumnHeader()}
                </tr>
            </thead>
            <tbody>
                {licenses.map((license: LicenseData) => (
                    <tr key={`row:${license.id}`}>
                        <td data-label="LicensePackageName">{license.package.name}</td>
                        <TableDataNoWrap data-label="OrderId">{license.orderId}</TableDataNoWrap>
                        <TableDataNoWrap data-label="StartDate">
                            {new Date(license.startDate).toLocaleDateString(locale)}
                        </TableDataNoWrap>
                        <TableDataNoWrap data-label="EndDate">
                            {license.endDate
                                ? new Date(license.endDate).toLocaleDateString(locale)
                                : "-"}
                        </TableDataNoWrap>

                        <TableDataNoWrap data-label="MaxUsers">
                            {license.package.unlimitedMaxUsers
                                ? t("license.unlimited")
                                : license.maxUsers}
                        </TableDataNoWrap>
                        <TableDataNoWrap data-label="Used">{license.seatsUsed}</TableDataNoWrap>
                        <TableDataNoWrap data-label="Status">
                            <Button
                                fluid
                                style={{
                                    backgroundColor: getLicenseStateColor(license.state),
                                    color: "#000000",
                                    fontWeight: "normal",
                                    border: "0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    cursor: "default",
                                }}
                            >
                                {t(`license.states.${license.state}`) +
                                    (license.package.companyWide
                                        ? `, ${t("license.company_wide")}`
                                        : ``)}
                            </Button>
                        </TableDataNoWrap>
                        {renderActionColumnData(license)}
                        {renderDeleteLicenseConfirmation()}
                    </tr>
                ))}
                {licenses.length > 1 && (
                    <tr>
                        <TableDataNoWrap colSpan={5}>
                            <b>{t("license_table.total")}</b>
                        </TableDataNoWrap>
                        <TableDataNoWrap>
                            <b>{anyUnlimitedLicense ? t("license.unlimited") : totalSeats}</b>
                        </TableDataNoWrap>
                        <TableDataNoWrap colSpan={3}>
                            <b>{totalSeatsUsed}</b>
                        </TableDataNoWrap>
                    </tr>
                )}
            </tbody>
        </table>
    );
};
