import { TranslationFunction } from "../../components/ApplicationProvider";
import { IGateway, MaybeAssociatedClientDevice } from "../../graphql";

export function findExternalCompanyIdKey(
    printerSerialToSearch: string | undefined,
    orphanedClientsData: MaybeAssociatedClientDevice[],
    gatewayListData?: IGateway[]
): string | undefined {
    // Return early if invalid inputs
    if (
        !printerSerialToSearch ||
        !Array.isArray(orphanedClientsData) ||
        orphanedClientsData.length === 0
    ) {
        return undefined;
    }

    // Find the matched item
    const matchedItem = orphanedClientsData.find(
        (item) => item.thingName === printerSerialToSearch
    );

    // If there's no match, return undefined
    if (!matchedItem) {
        return undefined;
    }

    // Return externalCompanyId if available from matchedItem
    if (matchedItem.externalCompanyId) {
        return matchedItem.externalCompanyId;
    }

    // If no externalCompanyId in matchedItem, search in gatewayListData (only if valid)
    return matchedItem.coreDeviceThingName && gatewayListData?.length
        ? gatewayListData.find(
              (item) => item.coreDeviceThingName === matchedItem.coreDeviceThingName
          )?.externalCompanyId
        : undefined;
}

export default function renderOwnedByLabels({
    companiesListItem,
    showAllCompaniesList,
    toggleShowAll,
    t,
}: {
    companiesListItem: { id: number; name: string }[];
    showAllCompaniesList: boolean;
    toggleShowAll: () => void;
    t: TranslationFunction;
}) {
    // If no companies exist
    if (!companiesListItem?.length) {
        return (
            <label data-testid="printer_owned_by_label">
                {t("gateways.owned_by")}: {t("gateways.gateways_no_site")}
            </label>
        );
    }

    // Render company labels
    const visibleCompanies = showAllCompaniesList
        ? companiesListItem
        : companiesListItem.slice(0, 1);

    const companyLabels = visibleCompanies.map((item, index) => (
        <label data-testid={`printer_owned_by_label_${index}`} key={item.id}>
            {t("gateways.owned_by")}: {item.name}
        </label>
    ));

    // Render toggle link
    const toggleLink =
        companiesListItem.length > 1 ? (
            <a onClick={toggleShowAll}>
                {showAllCompaniesList
                    ? t("gateways.show_less")
                    : `+${companiesListItem.length - 1} ${t("gateways.more")}`}
            </a>
        ) : null;

    return (
        <>
            {companyLabels}
            {toggleLink}
        </>
    );
}
