import styled from "grabcad-ui-elements";

/*
 * These are the specific styles for the VersionManagement screen
 * and components thereon
 */

export const RadioButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    height: 16px;
`;

export const RadioButton = styled.input`
    height: 16px;
    width: 16px;
`;

export const Label = styled.label`
    margin-left: 10px;
    margin-right: 20px;
    line-height: 16px;
`;

export const H3 = styled.h3`
    height: 14px;
    line-height: 16px;
    margin-top: 32px;
    font-size: 14px;
    font-weight: 700;
`;

export const H2 = styled.h2`
    height: 22px;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 700;
`;
