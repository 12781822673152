import { FunctionComponent, useContext } from "react";
import { Dropdown } from "grabcad-ui-elements";
import { ApplicationContext } from "../components/ApplicationProvider";
import { PrinterGroup } from "../graphql";
import { PRINTER_GROUPS } from "@grabcad/company-server-shared/dist/graphql/Queries/PrinterGroups";
import { useQuery } from "@apollo/client";
import { Notifier } from "./Notifier";
import { StyledLabel } from "./UtilityComponents";

interface IPrinterGroupMultiSelect {
    companyId?: number;
    ids: number[];
    onChange: (ids: number[]) => void;
    labelFontSize?: number;
    warning?: string;
}

export const PrinterGroupMultiSelect: FunctionComponent<IPrinterGroupMultiSelect> = (props) => {
    const { t } = useContext(ApplicationContext);
    const { loading: loadingPrinterGroups, data } = useQuery<{ printerGroups: PrinterGroup[] }>(
        PRINTER_GROUPS,
        {
            onError: (error) => Notifier.error(error),
        }
    );

    if (loadingPrinterGroups) {
        return <div className="ui loader active" />;
    }

    return (
        <>
            {props.labelFontSize && (
                <StyledLabel labelFontSize={props.labelFontSize}>
                    {t("printer_group_multiselect.assign")}
                </StyledLabel>
            )}
            {props.warning && (
                <p className="ui yellow message qa-printerGroupSelectWarning">{props.warning}</p>
            )}
            <Dropdown
                className="qa-printerGroupMultiSelect"
                options={data?.printerGroups?.map((group) => ({
                    key: group.id,
                    text: group.name,
                    value: group.id,
                }))}
                value={props.ids}
                disabled={!data?.printerGroups?.length}
                placeholder={
                    !data?.printerGroups?.length
                        ? t("printer_group_multiselect.no_groups")
                        : t("printer_group_multiselect.multiple_selection")
                }
                onChange={(e, newData) => {
                    props.onChange(newData.value as number[]);
                }}
                fluid
                multiple
                selection
            />
        </>
    );
};
