import { FunctionComponent } from "react";
import { DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { H3 } from "./VersionsStyles";
import { Dropdown } from "grabcad-ui-elements";

type VersionsDropdownProps = {
    testId: string;
    visible: boolean;
    heading: string;
    placeholder: string;
    options: { text: string; value: number }[];
    value?: number;
    onChange: (event: React.SyntheticEvent<HTMLElement>, id: number) => void;
};

/**
 * Dropdown component for selecting versions or tags
 * This is simply a simple wrapper for grabcad-ui-components Dropdown
 */
export const VersionsDropdown: FunctionComponent<VersionsDropdownProps> = ({
    testId,
    visible,
    heading,
    placeholder,
    options,
    onChange,
    value,
}) => {
    if (!visible) {
        return <></>;
    }

    const dropdownProps: DropdownProps = {
        className: "role-selector",
        placeholder,
        options,
        fluid: true,
        multiple: false,
        selection: true,
        onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            const id = Number(data.value ?? -1);
            onChange(event, id);
        },
        renderLabel: (item: DropdownItemProps) => item.label,
        value,
    };

    return (
        <div data-testid={testId} style={{ marginTop: "32px" }}>
            <H3>{heading}</H3>
            <Dropdown {...dropdownProps} />
        </div>
    );
};
